import useFetchService from './useFetchService';
import Sensor from '../../models/Sensor';

export default function useSensorService() {
    const { getRequest, deleteRequest, postRequest, putRequest } = useFetchService();

    function getSensorList(limit: number, offset: number) {
        return getRequest<Sensor[], { limit: number; offset: number }>('motionsensors', { limit, offset });
    }

    function deleteSensor(id: string) {
        return deleteRequest(`motionsensors/${id}`);
    }

    function createSensor(sensor: Sensor) {
        return postRequest('motionsensors', sensor);
    }

    function updateSensor(sensor: Sensor) {
        return putRequest(`motionsensors/${sensor.id}`, sensor);
    }

    return { getSensorList, deleteSensor, createSensor, updateSensor };
}
