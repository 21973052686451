import * as React from 'react';
import { useState } from 'react';
import { useMutation } from 'react-query';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AxiosError } from 'axios';

import Room from '../../../models/Room';
import { ErrorData } from '../../common/types/types';
import ErrorNotification from '../../notification/ErrorNotification';
import useEventService from '../../services/useEventService';

export default function AddEventDialog({
    open,
    onSuccess,
    onClose,
    roomList,
}: {
    open: boolean;
    onSuccess: () => void;
    onClose: () => void;
    roomList: Room[];
}) {
    const [eventStartDateTime, setEventStartDateTime] = useState<Date>(new Date());
    const [eventEndDateTime, setEventEndDateTime] = useState<Date>(new Date());
    const [eventName, setEventName] = useState<string>('');
    const [eventRoom, setEventRoom] = useState<Room>(new Room());
    const [openErrorNotification, setOpenErrorNotification] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>('');

    const { createEvent } = useEventService();

    const resetForm = () => {
        setEventStartDateTime(new Date());
        setEventEndDateTime(new Date());
        setEventName('');
        setEventRoom(new Room());
        setOpenErrorNotification(false);
        setErrorMessage('');
    };

    const createEventQuery = useMutation(
        'Create Event',
        () =>
            createEvent({
                startDatetime: eventStartDateTime.toISOString(),
                endDatetime: eventEndDateTime.toISOString(),
                name: eventName,
                room: eventRoom,
            }),
        {
            onSuccess: () => {
                resetForm();
                onSuccess();
            },
            onError: (error: AxiosError<ErrorData>) => {
                setErrorMessage(error.response?.data.body.message);
                setOpenErrorNotification(true);
            },
        },
    );

    const handleAddEvent = () => {
        createEventQuery.mutate();
    };

    const handleClose = () => {
        resetForm();
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Ajouter</DialogTitle>
            <DialogContent>
                <TextField
                    id="standard-basic"
                    label="Nom de la réunion"
                    variant="standard"
                    value={eventName}
                    onChange={(event) => setEventName(event.target.value)}
                />
            </DialogContent>
            <DialogContent>
                <Autocomplete
                    options={roomList}
                    getOptionLabel={(room: Room) => room.name!}
                    renderInput={(params) => <TextField {...params} label="Salle" />}
                    onChange={(_, value) => {
                        setEventRoom(value!);
                    }}
                />
            </DialogContent>
            <DialogContent>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                        label="Début de la réunion"
                        value={eventStartDateTime}
                        onChange={(newValue) => {
                            if (newValue !== null) {
                                setEventStartDateTime(newValue);
                            }
                        }}
                        format="dd/MM/yyyy HH:mm"
                    />
                </LocalizationProvider>
            </DialogContent>
            <DialogContent>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                        label="Fin de la réunion"
                        value={eventEndDateTime}
                        onChange={(newValue) => {
                            if (newValue !== null) {
                                setEventEndDateTime(newValue);
                            }
                        }}
                        format="dd/MM/yyyy HH:mm"
                    />
                </LocalizationProvider>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button variant="contained" onClick={handleAddEvent}>
                    Ajouter
                </Button>
            </DialogActions>
            <ErrorNotification open={openErrorNotification} message={errorMessage} />
        </Dialog>
    );
}
