import Keycloak from 'keycloak-js';

const KeycloakConfig = {
    url: Config.KEYCLOAK_URL,
    realm: Config.KEYCLOAK_REALM,
    clientId: Config.KEYCLOAK_CLIENT_ID,
};

const keycloakInstance = new Keycloak(KeycloakConfig);

export default keycloakInstance;
