import * as React from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { AxiosError } from 'axios';

import Sensor from '../../../models/Sensor';
import { ErrorData } from '../../common/types/types';
import useSensorService from '../../services/useSensorService';

export default function DeleteSensorDialog({
    open,
    onSuccess,
    onError,
    onClose,
    sensor,
}: {
    open: boolean;
    onSuccess: () => void;
    onError: (message: string) => void;
    onClose: () => void;
    sensor: Sensor;
}) {
    const { deleteSensor } = useSensorService();

    const deleteSensorQuery = useMutation(() => deleteSensor(sensor.id!), {
        onSuccess: () => {
            onSuccess();
            toast.success('Suppression validée');
        },
        onError: (error: AxiosError<ErrorData>) => {
            onError(error.response?.data.body.message!);
            onClose();
        },
    });

    return (
        <Dialog open={open}>
            <DialogTitle>Supprimer</DialogTitle>
            <DialogContent>Etes-vous sur de vouloir supprimer le capteur ?</DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button variant="contained" onClick={() => deleteSensorQuery.mutate()}>
                    Supprimer
                </Button>
            </DialogActions>
        </Dialog>
    );
}
