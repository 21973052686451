import * as React from 'react';
import { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';

import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';

import DeleteBullesDialog from './DeleteBullesDialog';
import Bulle from '../../../models/Bulle';
import Room from '../../../models/Room';

import styles from './BullesListPage.module.scss';

interface BullesListPageProps {
    handleEditItem: (item: Room | Bulle, type: 'room' | 'bulle') => void;
    setIsAddingBulle: (value: ((prevState: boolean) => boolean) | boolean) => void;
    bulles: Bulle[];
    handleCancel: () => void;
    setBulles: (value: ((prevState: Bulle[]) => Bulle[]) | Bulle[]) => void;
}

export default function BullesListPage({
    handleEditItem,
    setIsAddingBulle,
    bulles,
    handleCancel,
    setBulles,
}: BullesListPageProps) {
    const [openDeleteBulleDialog, setOpenDeleteBulleDialog] = useState<boolean>(false);
    const [selectedBulle, setSelectedBulle] = useState<Bulle>();
    const [openErrorNotification, setOpenErrorNotification] = useState<boolean>(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setOpenErrorNotification(false);
        }, 5000);

        return () => {
            clearTimeout(timer);
        };
    }, [openErrorNotification]);

    enum ButtonOption {
        DELETE,
    }

    const handleButtonClick = (bulle: Bulle, buttonOption: ButtonOption) => {
        setSelectedBulle(bulle);
        switch (buttonOption) {
            case ButtonOption.DELETE:
                setOpenDeleteBulleDialog(true);
                break;
            default:
                break;
        }
    };

    return (
        <div className={styles.container}>
            {bulles && (
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 100 }} aria-label="simple table">
                        <TableBody>
                            {bulles &&
                                bulles.map((bulle) => (
                                    <TableRow
                                        key={bulle.uuid}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        onClick={() => {
                                            handleEditItem(bulle, 'bulle');
                                        }}
                                        className={styles.row}
                                    >
                                        <TableCell component="th" scope="row">
                                            {bulle.name}
                                        </TableCell>
                                        <TableCell align="right" padding="none">
                                            <Button
                                                title="Delete"
                                                onClick={() => handleButtonClick(bulle, ButtonOption.DELETE)}
                                            >
                                                <DeleteIcon />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            {selectedBulle && (
                <DeleteBullesDialog
                    open={openDeleteBulleDialog}
                    onSuccess={() => {
                        setOpenDeleteBulleDialog(false);
                        setSelectedBulle(undefined);
                        setOpenErrorNotification(false);
                        if (!selectedBulle?.uuid) {
                            setIsAddingBulle(false);
                        }
                        handleCancel();
                        toast.info('Bulle suprimmée');
                    }}
                    onError={() => {
                        setOpenErrorNotification(true);
                    }}
                    onClose={() => {
                        setOpenDeleteBulleDialog(false);
                        setSelectedBulle(undefined);
                    }}
                    bulle={selectedBulle}
                    bulles={bulles}
                    setBulles={setBulles}
                />
            )}
            <ToastContainer />
        </div>
    );
}
