import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams, Navigate, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { fromDate, getLocalTimeZone, now } from '@internationalized/date';
import AddIcon from '@mui/icons-material/Add';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import PeopleIcon from '@mui/icons-material/People';
import SearchIcon from '@mui/icons-material/Search';
import SensorsIcon from '@mui/icons-material/Sensors';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Chip,
    Box,
    Typography,
    CircularProgress,
    CardMedia,
} from '@mui/material';
import dayjs from 'dayjs';

import useEventService from '../../services/useEventService';
import useRoomService from '../../services/useRoomService';

import styles from './RoomDetailsGuest.module.scss';

const roomImageMapping: { [key: string]: string } = {
    'salle-adier': '/SalleAdier.jpeg',
    'salle-eiffel': '/SalleEiffel.jpeg',
    'salle-cse': '/SalleCSE.jpeg',
    'salle-fait': '/SalleFait.jpeg',
    'salle the': '/conference_room.jpg',
    'salle-on': '/SalleOn.jpeg',
    'salle-projection': '/SalleProjo.jpeg',
};
const MS_PER_MINUTES = 60000;
const STEP_SIZE = 15;
function RoomDetails() {
    const { roomUuid, roomName } = useParams<{ roomUuid: string; roomName: string }>();
    const { getEventListPeriod, createGoogleEvent, getRoomEvents } = useEventService();
    const { getRoomList, fetchRoomStatus, fetchRoomSensorStatus } = useRoomService();
    const navigate = useNavigate();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [formatedDates, setFormatedDates] = useState({ starDate: '', endDate: '', load: false });
    const [findRoomOpen, setFindRoomOpen] = useState(false);
    const [formValues, setFormValues] = useState({
        uuid: roomUuid,
        title: '',
        startTime: dayjs().format('HH:mm'),
        endTime: dayjs().add(1, 'hour').format('HH:mm'),
        date: dayjs().format('YYYY-MM-DD'),
        attendees: [] as string[],
    });
    const getStatusColor = (status: undefined | string) => {
        switch (status?.toLowerCase()) {
            case 'libre':
                return 'green';
            case 'occupée':
                return 'red';
            default:
                return 'orange';
        }
    };
    const getSensorStatusColor = (status: String | undefined) => {
        switch (status?.toLowerCase()) {
            case 'on':
                return 'red';
            case 'off':
                return 'green';
            default:
                return 'gray';
        }
    };
    const [emailInput, setEmailInput] = useState('');

    const { data: events } = useQuery(['roomEvents', roomUuid], () => getRoomEvents(roomUuid!), {
        enabled: !!roomUuid,
    });

    const { data: eventsBooked } = useQuery(
        ['roomEvents', roomUuid],
        () => getEventListPeriod(50, 0, formatedDates.starDate, formatedDates.endDate),
        { enabled: formatedDates.load },
    );

    const { data: rooms } = useQuery('rooms', () => getRoomList(50, 0));

    const {
        data: roomStatus,
        isLoading: isLoadingStatus,
        error: errorStatus,
    } = useQuery(['roomStatus', roomName], () => fetchRoomStatus(roomName!), { enabled: !!roomName });

    const {
        data: roomSensorStatus,
        isLoading: isLoadingSensorStatus,
        error: errorSensorStatus,
    } = useQuery(['roomSensorStatus', roomUuid], () => fetchRoomSensorStatus(roomUuid!), { enabled: !!roomUuid });

    if (!roomUuid || !roomName) {
        return <Navigate to="/" replace />;
    }

    const handleClickOpen = () => {
        setDialogOpen(true);
    };

    const handleClose = () => {
        setDialogOpen(false);
    };

    const handleClickOpenRoom = () => {
        setFindRoomOpen(true);
    };

    const handleCloseRoom = () => {
        setFindRoomOpen(false);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const handleAddEmail = () => {
        if (emailInput.endsWith('@takima.fr') && !formValues.attendees.includes(emailInput)) {
            setFormValues((prevState) => ({
                ...prevState,
                attendees: [...prevState.attendees, emailInput],
            }));
            setEmailInput('');
        } else {
            toast.error('Invalid email or already added. Please enter a valid @takima.fr email.');
        }
    };

    const handleDeleteEmail = (emailToDelete: string) => {
        setFormValues((prevState) => ({
            ...prevState,
            attendees: prevState.attendees.filter((email) => email !== emailToDelete),
        }));
    };

    const handleTimeButtonClick = (minutes: number) => {
        const startTime = dayjs(`${formValues.date}T${formValues.startTime}`);
        const endTime = startTime.add(minutes, 'minute').format('HH:mm');
        setFormValues((prevState) => ({
            ...prevState,
            endTime,
        }));
    };

    const handleSearch = () => {
        const selectedStart = new Date(`${formValues.date ?? ''}T${formValues.startTime ?? ''}`);
        const selectedEnd = new Date(`${formValues.date ?? ''}T${formValues.endTime ?? ''}`);
        const nbrPlaces: number = Number(`${formValues.title}`);
        const nowDate = new Date();

        // Check if the selected time is more than 15min in the past
        if (selectedStart < new Date(nowDate.valueOf() - 15 * MS_PER_MINUTES)) {
            toast.error('Impossible de faire une recherche dans le passé');
            return;
        }

        // Check if the selected time is outside the allowed range (09:00 to 19:00)
        const startHour = selectedStart.getHours();
        const endHour = selectedEnd.getHours();
        if (startHour < 9 || endHour >= 19) {
            toast.error('Heure de recherche doit être comprise entre 9h00 et 19h00.');
            return;
        }
        const formatedStartDate = fromDate(selectedStart, now(getLocalTimeZone()).timeZone).toString().split('[')[0];
        const formatedEndDate = fromDate(selectedEnd, now(getLocalTimeZone()).timeZone).toString().split('[')[0];
        setFormatedDates({ starDate: formatedStartDate, endDate: formatedEndDate, load: true });
        const roomMap = new Map(rooms?.map((room) => [room.uuid, { name: room.name, place: room.capacity }]));
        eventsBooked?.forEach((event) => {
            if (roomMap.has(event?.room?.uuid)) {
                roomMap.delete(event?.room?.uuid);
            }
        });
        setFindRoomOpen(false);
        let finded = false;
        if (roomMap.size >= 1) {
            roomMap.forEach((room, key) => {
                if (room?.place! >= nbrPlaces && !finded) {
                    setFormatedDates({ starDate: formatedStartDate, endDate: formatedEndDate, load: false });
                    navigate(`/roomdetails-guest/${key}/${room.name}`);
                    finded = true;
                }
            });
        }
        if (!finded) {
            toast.error(`Aucune salle n’est disponible pour ce créneau`, { toastId: 'error1' });
        }
    };

    const toNeareastLowerStep = (time: Date, step: number) => {
        let offset = 0;
        let rounded = Math.floor(time.getMinutes() / step) * step;
        if (rounded === 60) {
            offset = 1;
            rounded = 0;
        }
        return new Date(time.setHours(time.getHours() + offset, rounded));
    };

    const toNeareastUpperStep = (time: Date, step: number) => {
        let offset = 0;
        let rounded = Math.ceil(time.getMinutes() / step) * step;
        if (rounded === 60) {
            offset = 1;
            rounded = 0;
        }
        return new Date(time.setHours(time.getHours() + offset, rounded));
    };

    const handleSubmit = async () => {
        const selectedStart = new Date(`${formValues.date ?? ''}T${formValues.startTime ?? ''}`);
        const selectedEnd = new Date(`${formValues.date ?? ''}T${formValues.endTime ?? ''}`);

        const nowDate = new Date();

        // Check if the selected time is in the past
        if (selectedStart < new Date(nowDate.valueOf() - 30 * MS_PER_MINUTES)) {
            toast.error('Impossible de faire une réservation dans le passé');
            return;
        }

        // Check if the selected time is outside the allowed range (09:00 to 18:00)
        const startHour = selectedStart.getHours();
        const endHour = selectedEnd.getHours();
        if (startHour < 9 || endHour >= 20) {
            toast.error('Heure de réservation doit être comprise entre 9h00 et 20h00.');
            return;
        }

        const isOverlapping = events?.some((event) => {
            const eventStart = new Date(event.startDatetime ?? '');
            const eventEnd = new Date(event.endDatetime ?? '');
            return (
                (selectedStart >= eventStart && selectedStart < eventEnd) ||
                (selectedEnd > eventStart && selectedEnd <= eventEnd) ||
                (selectedStart <= eventStart && selectedEnd >= eventEnd)
            );
        });

        if (isOverlapping) {
            toast.error('L’heure sélectionnée chevauche un événement existant.');
            return;
        }
        // Formatte start Date
        let startFormattedDate = toNeareastLowerStep(selectedStart, STEP_SIZE);
        startFormattedDate = new Date(startFormattedDate.setHours(startFormattedDate.getHours() + 2)); // Add timeZone
        const startFormattedDateString = startFormattedDate.toISOString().replace('Z', '+02:00');
        // Formatte end Date
        let endFormattedDate = toNeareastUpperStep(selectedEnd, STEP_SIZE);
        endFormattedDate = new Date(endFormattedDate.setHours(endFormattedDate.getHours() + 2));
        const endFormattedDateString = endFormattedDate.toISOString().replace('Z', '+02:00');
        try {
            await createGoogleEvent({
                uuid: formValues.uuid!,
                startDatetime: startFormattedDateString,
                endDatetime: endFormattedDateString,
                name: formValues.title,
                status: 'confirmed',
                organizerEmail: '',
                room: { uuid: roomUuid },
                attendees: formValues.attendees,
            });
            toast.success('Réservation soumise avec succès!');
            handleClose();
        } catch (error) {
            toast.error('Erreur lors de la soumission de la réservation.');
        }
    };

    const handleSubmitAndAddEmail = () => {
        if (emailInput === '') {
            handleSubmit();
        } else {
            toast.error('Veuillez valider l’ajout de l’invité');
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.mediaContainer}>
                <h2 className={styles.roomName}> {roomName}</h2>
                <div className={styles.infos}>
                    <CardMedia
                        component="img"
                        className={styles.media}
                        image={roomImageMapping[roomName] || '/conference_room.jpg'}
                        alt={`${roomName} image`}
                    />
                </div>
                {/* eslint-disable-next-line no-nested-ternary */}
                {!isLoadingStatus && !isLoadingSensorStatus ? (
                    errorStatus || errorSensorStatus ? (
                        <div>Erreur lors du chargement du status de la salle</div>
                    ) : (
                        <div className={styles.bottomMedia}>
                            <Typography variant="subtitle1" color="textSecondary" className={styles.capacity}>
                                {rooms?.find((r) => r.uuid === roomUuid)?.capacity}
                                <PeopleIcon fontSize="large" className={styles.icon} />
                            </Typography>
                            <div className={styles.statusContainer}>
                                <Typography
                                    variant="h5"
                                    fontSize={20}
                                    className={styles.status}
                                    style={{ color: getStatusColor(roomStatus?.toString()) }}
                                >
                                    ⬤ {roomStatus}
                                </Typography>
                                <SensorsIcon
                                    fontSize="small"
                                    className={styles.sensorIcon}
                                    style={{ color: getSensorStatusColor(roomSensorStatus) }}
                                />
                            </div>
                        </div>
                    )
                ) : (
                    <CircularProgress />
                )}
            </div>
            <div className={styles.buttonContainer}>
                <Button
                    color="info"
                    variant="contained"
                    onClick={handleClickOpenRoom}
                    className={styles.buttonSearch}
                    style={{ textTransform: 'none' }}
                >
                    Chercher une autre salle <SearchIcon style={{ marginLeft: '10px' }} />
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={handleClickOpen}
                    disabled={roomStatus !== 'Libre'}
                    className={styles.button}
                    style={{ textTransform: 'none' }}
                >
                    Réserver <EditCalendarIcon style={{ marginLeft: '10px' }} />
                </Button>
            </div>
            <Dialog open={findRoomOpen} onClose={handleCloseRoom}>
                <DialogTitle>Trouver une autre salle</DialogTitle>
                <DialogContent>
                    <TextField
                        margin="dense"
                        name="title"
                        label="Nombre de personnes"
                        type="number"
                        InputProps={{ inputProps: { min: 0 } }}
                        fullWidth
                        value={formValues.title}
                        onChange={handleChange}
                        className={styles.title}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' },
                            justifyContent: 'space-between',
                            gap: 0.5,
                        }}
                    >
                        <TextField
                            className={styles.dateField}
                            margin="dense"
                            name="date"
                            label="Date"
                            type="date"
                            disabled
                            fullWidth
                            value={formValues.date}
                            onChange={handleChange}
                        />
                        <TextField
                            margin="dense"
                            name="startTime"
                            label="Début"
                            type="time"
                            fullWidth
                            value={formValues.startTime}
                            onChange={handleChange}
                            className={styles.fieldMargin}
                        />
                        <TextField
                            margin="dense"
                            name="endTime"
                            label="Fin"
                            type="time"
                            fullWidth
                            value={formValues.endTime}
                            onChange={handleChange}
                            className={styles.fieldMargin}
                        />
                    </Box>
                    <Typography variant="body2" color="textSecondary" style={{ margin: '10px 0' }}>
                        Sélection rapide du temps de réservation :
                    </Typography>
                    <Button
                        className={styles.timeButton}
                        variant="contained"
                        startIcon={<HourglassTopIcon />}
                        color="primary"
                        onClick={() => handleTimeButtonClick(15)}
                    >
                        15m
                    </Button>
                    <Button
                        className={styles.timeButton}
                        variant="contained"
                        startIcon={<HourglassTopIcon />}
                        color="primary"
                        onClick={() => handleTimeButtonClick(30)}
                    >
                        30m
                    </Button>
                    <Button
                        className={styles.timeButton}
                        variant="contained"
                        startIcon={<HourglassTopIcon />}
                        color="primary"
                        onClick={() => handleTimeButtonClick(45)}
                    >
                        45m
                    </Button>
                    <Button
                        className={styles.timeButton}
                        variant="contained"
                        startIcon={<HourglassTopIcon />}
                        color="primary"
                        onClick={() => handleTimeButtonClick(60)}
                    >
                        1H
                    </Button>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseRoom} color="primary">
                        Annuler
                    </Button>
                    <Button variant="contained" onClick={handleSearch} color="primary">
                        Rechercher
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={dialogOpen} onClose={handleClose}>
                <DialogTitle>Réserver la salle</DialogTitle>
                <DialogContent>
                    <TextField
                        margin="dense"
                        name="title"
                        label="Titre"
                        type="text"
                        fullWidth
                        value={formValues.title}
                        onChange={handleChange}
                        className={styles.title}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' },
                            justifyContent: 'space-between',
                            gap: 0.5,
                        }}
                    >
                        <TextField
                            className={styles.dateField}
                            margin="dense"
                            name="date"
                            label="Date"
                            disabled
                            type="date"
                            fullWidth
                            value={formValues.date}
                            onChange={handleChange}
                        />
                        <TextField
                            margin="dense"
                            name="startTime"
                            label="Début"
                            type="time"
                            fullWidth
                            value={formValues.startTime}
                            onChange={handleChange}
                            className={styles.fieldMargin}
                        />
                        <TextField
                            margin="dense"
                            name="endTime"
                            label="Fin"
                            type="time"
                            fullWidth
                            value={formValues.endTime}
                            onChange={handleChange}
                            className={styles.fieldMargin}
                        />
                    </Box>
                    <Typography variant="body2" color="textSecondary" style={{ margin: '10px 0' }}>
                        Sélection rapide du temps de réservation :
                    </Typography>
                    <Button
                        className={styles.timeButton}
                        variant="contained"
                        startIcon={<HourglassTopIcon />}
                        color="primary"
                        onClick={() => handleTimeButtonClick(15)}
                    >
                        15m
                    </Button>
                    <Button
                        className={styles.timeButton}
                        variant="contained"
                        startIcon={<HourglassTopIcon />}
                        color="primary"
                        onClick={() => handleTimeButtonClick(30)}
                    >
                        30m
                    </Button>
                    <Button
                        className={styles.timeButton}
                        variant="contained"
                        startIcon={<HourglassTopIcon />}
                        color="primary"
                        onClick={() => handleTimeButtonClick(45)}
                    >
                        45m
                    </Button>
                    <Button
                        className={styles.timeButton}
                        variant="contained"
                        startIcon={<HourglassTopIcon />}
                        color="primary"
                        onClick={() => handleTimeButtonClick(60)}
                    >
                        1H
                    </Button>
                    <form method="dialog">
                        <Box display="flex" alignItems="center" className={styles.formBox}>
                            <TextField
                                margin="dense"
                                label="Ajouter un invité (@takima.fr)"
                                type="email"
                                fullWidth
                                value={emailInput}
                                onChange={(e) => setEmailInput(e.target.value)}
                            />
                            <Button onClick={handleAddEmail} color="primary" type="submit">
                                <AddIcon />
                            </Button>
                        </Box>
                    </form>
                    <Box mt={2}>
                        {formValues.attendees.map((email) => (
                            <Chip
                                key={email}
                                label={email}
                                onDelete={() => handleDeleteEmail(email)}
                                style={{ margin: '2px' }}
                            />
                        ))}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Annuler
                    </Button>
                    <Button variant="contained" onClick={handleSubmitAndAddEmail} color="primary">
                        Réserver
                    </Button>
                </DialogActions>
            </Dialog>
            <ToastContainer />
        </div>
    );
}

export default RoomDetails;
