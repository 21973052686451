import * as React from 'react';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { ToastContainer } from 'react-toastify';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
    Button,
    CircularProgress,
    Fab,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { AxiosError } from 'axios';

import AddSensorDialog from './AddSensorDialog';
import DeleteSensorDialog from './DeleteSensorDialog';
import UpdateSensorDialog from './UpdateSensorDialog';
import Sensor from '../../../models/Sensor';
import ErrorPage from '../../common/error/ErrorPage';
import { ErrorData } from '../../common/types/types';
import ErrorNotification from '../../notification/ErrorNotification';
import useSensorService from '../../services/useSensorService';

import styles from './SensorListPage.module.scss';

export default function SensorListPage() {
    const { getSensorList } = useSensorService();
    const [openAddSensorDialog, setOpenAddSensorDialog] = useState<boolean>(false);
    const [openUpdateSensorDialog, setOpenUpdateSensorDialog] = useState<boolean>(false);
    const [openDeleteSensorDialog, setOpenDeleteSensorDialog] = useState<boolean>(false);
    const [selectedSensor, setSelectedSensor] = useState<Sensor>();
    const [hasServerError, setHasServerError] = useState<boolean>(false);
    const [openErrorNotification, setOpenErrorNotification] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const {
        data: sensors,
        refetch: refetchSensorList,
        isLoading,
    } = useQuery('SensorListPage', () => getSensorList(20, 0), {
        onError: (error: AxiosError<ErrorData>) => {
            if (error.response && error.response.status === 500) {
                setHasServerError(true);
            }
        },
    });

    useEffect(() => {
        const timer = setTimeout(() => {
            setOpenErrorNotification(false);
        }, 5000);

        return () => {
            clearTimeout(timer);
        };
    }, [openErrorNotification]);

    enum ButtonOption {
        UPDATE,
        DELETE,
    }

    const handleButtonClick = (sensor: Sensor, buttonOption: ButtonOption) => {
        setSelectedSensor(sensor);
        switch (buttonOption) {
            case ButtonOption.UPDATE:
                setOpenUpdateSensorDialog(true);
                break;
            case ButtonOption.DELETE:
                setOpenDeleteSensorDialog(true);
                break;
            default:
                break;
        }
    };

    return (
        <div className={styles.container}>
            {isLoading ? (
                <CircularProgress />
            ) : (
                !hasServerError &&
                sensors && (
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID du capteur</TableCell>
                                    <TableCell>État du capteur</TableCell>
                                    <TableCell>Classe du capteur</TableCell>
                                    <TableCell>Nom du capteur</TableCell>
                                    <TableCell align="right" />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sensors &&
                                    sensors.map((sensor) => (
                                        <TableRow
                                            key={sensor.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {sensor.sensorId}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {sensor.state}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {sensor.deviceClass}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {sensor.friendlyName}
                                            </TableCell>
                                            <TableCell align="right" padding="none">
                                                <Button
                                                    title="Update"
                                                    onClick={() => handleButtonClick(sensor, ButtonOption.UPDATE)}
                                                >
                                                    <EditIcon />
                                                </Button>
                                                <Button
                                                    title="Delete"
                                                    onClick={() => handleButtonClick(sensor, ButtonOption.DELETE)}
                                                >
                                                    <DeleteIcon />
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )
            )}
            <Fab
                color="primary"
                aria-label="add"
                onClick={() => setOpenAddSensorDialog(true)}
                sx={{ position: 'fixed', bottom: 50 }}
                className={styles.button}
            >
                <AddIcon />
            </Fab>
            <AddSensorDialog
                open={openAddSensorDialog}
                onSuccess={() => {
                    setOpenAddSensorDialog(false);
                    refetchSensorList();
                }}
                onClose={() => {
                    setOpenAddSensorDialog(false);
                }}
            />
            {selectedSensor && (
                <>
                    <UpdateSensorDialog
                        open={openUpdateSensorDialog}
                        onSuccess={() => {
                            setOpenUpdateSensorDialog(false);
                            setSelectedSensor(undefined);
                            refetchSensorList();
                        }}
                        onClose={() => {
                            setOpenUpdateSensorDialog(false);
                            setSelectedSensor(undefined);
                        }}
                        selectedSensor={selectedSensor}
                    />
                    <DeleteSensorDialog
                        open={openDeleteSensorDialog}
                        onSuccess={() => {
                            setOpenDeleteSensorDialog(false);
                            setSelectedSensor(undefined);
                            setOpenErrorNotification(false);
                            setErrorMessage('');
                            refetchSensorList();
                        }}
                        onError={(message) => {
                            setOpenErrorNotification(true);
                            setErrorMessage(message);
                        }}
                        onClose={() => {
                            setOpenDeleteSensorDialog(false);
                            setSelectedSensor(undefined);
                        }}
                        sensor={selectedSensor}
                    />
                </>
            )}
            <ErrorNotification open={openErrorNotification} message={errorMessage} />
            {hasServerError && <ErrorPage errorCode={500} />}
            <ToastContainer />
        </div>
    );
}
