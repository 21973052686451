import * as React from 'react';
import { useQuery } from 'react-query';

import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from 'recharts';

import useBulleOccupancyService from '../../services/useBulleOccupancyService';
import useBullesService from '../../services/useBullesService';

import styles from './StatPage.module.scss';

const getXAxisLabels = (period: string): string[] => {
    const now = new Date();
    const daysInMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
    switch (period) {
        case 'daily':
            return Array.from({ length: 10 }, (_, i) => `${i + 9}h`);
        case 'weekly':
            return ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven'];
        case 'monthly':
            return Array.from({ length: daysInMonth }, (_, i) => `${i + 1}`);
        case 'yearly':
            return ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'];
        default:
            return [];
    }
};

const formatDuration = (duration: number): string => {
    const hours = Math.floor(duration / 60);
    const minutes = duration % 60;
    if (hours === 0) {
        return `${minutes}min`;
    }
    const minutesText = minutes > 0 ? `${minutes}min` : '';
    return `${hours}h ${minutesText}`.trim();
};

const processDailyData = (
    occupancyData: Map<string, number>,
    xAxisLabels: string[],
    occupancy: any,
    currentDayStart: Date,
    currentDayEnd: Date,
) => {
    const startTime = new Date(occupancy.startTime);
    const endTime = occupancy.endTime ? new Date(occupancy.endTime) : new Date();
    const { duration } = occupancy;

    if (startTime >= currentDayStart && startTime <= currentDayEnd) {
        for (let hour = 9; hour <= 18; hour += 1) {
            const key = `${hour}h`;
            if (endTime.getHours() <= hour) {
                const previousValue = occupancyData.get(key) ?? 0;
                occupancyData.set(key, previousValue + duration);
            }
        }
        let previousTotal = 0;
        xAxisLabels.forEach((label) => {
            const currentHourStats = occupancyData.get(label) ?? 0;
            const difference = Math.max(0, currentHourStats - previousTotal);
            occupancyData.set(label, difference);
            previousTotal = currentHourStats;
        });
    }
};

const processWeeklyData = (
    occupancyData: Map<string, number>,
    occupancy: any,
    currentWeekStart: Date,
    currentWeekEnd: Date,
) => {
    const startTime = new Date(occupancy.startTime);
    const { duration } = occupancy;

    if (startTime >= currentWeekStart && startTime <= currentWeekEnd) {
        const day = startTime.getDay();
        const key = ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'][day];
        const previousValue = occupancyData.get(key) ?? 0;
        occupancyData.set(key, previousValue + duration);
    }
};

const processMonthlyData = (
    occupancyData: Map<string, number>,
    occupancy: any,
    currentMonth: number,
    currentYear: number,
) => {
    const startTime = new Date(occupancy.startTime);
    const { duration } = occupancy;

    if (startTime.getMonth() === currentMonth && startTime.getFullYear() === currentYear) {
        const day = startTime.getDate();
        const key = `${day}`;
        const previousValue = occupancyData.get(key) ?? 0;
        occupancyData.set(key, previousValue + duration);
    }
};

const processYearlyData = (occupancyData: Map<string, number>, occupancy: any) => {
    const startTime = new Date(occupancy.startTime);
    const { duration } = occupancy;
    const month = startTime.getMonth();
    const key = ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'][month];
    const previousValue = occupancyData.get(key) ?? 0;
    occupancyData.set(key, previousValue + duration);
};

const processData = (bulleOccupancies: any[], period: string) => {
    const xAxisLabels = getXAxisLabels(period);
    const occupancyData = new Map<string, number>();

    xAxisLabels.forEach((label) => occupancyData.set(label, 0));

    const now = new Date();
    const currentMonth = now.getMonth();
    const currentYear = now.getFullYear();

    const currentWeekStart = new Date(now);
    currentWeekStart.setDate(now.getDate() - now.getDay() + 1);
    const currentWeekEnd = new Date(currentWeekStart);
    currentWeekEnd.setDate(currentWeekStart.getDate() + 4);

    const currentDayStart = new Date(now);
    currentDayStart.setHours(0, 0, 0, 0);
    const currentDayEnd = new Date(now);
    currentDayEnd.setHours(23, 59, 59, 999);

    bulleOccupancies.forEach((occupancy) => {
        switch (period) {
            case 'daily':
                processDailyData(occupancyData, xAxisLabels, occupancy, currentDayStart, currentDayEnd);
                break;
            case 'weekly':
                processWeeklyData(occupancyData, occupancy, currentWeekStart, currentWeekEnd);
                break;
            case 'monthly':
                processMonthlyData(occupancyData, occupancy, currentMonth, currentYear);
                break;
            case 'yearly':
                processYearlyData(occupancyData, occupancy);
                break;
            default:
                break;
        }
    });

    return Array.from(occupancyData.entries()).map(([name, occupation]) => ({ name, occupation }));
};

const formatYAxisTick = (value: number): string => formatDuration(value);

const updateDataEveryHour = (bulleOccupancies: any[], period: string) => {
    setInterval(() => {
        processData(bulleOccupancies, period);
    }, 3600000);
};

export default function BulleOverview({ period }: Readonly<{ period: string }>) {
    const { getBulleList } = useBullesService();
    const { getBulleOccupancyList } = useBulleOccupancyService();

    const { data: bulleList, isLoading: isBulleListLoading } = useQuery('bulleList', () => getBulleList(20, 0), {
        retry: false,
    });

    const {
        data: bulleOccupancyData,
        isLoading: isOccupancyLoading,
        error,
    } = useQuery(
        ['bulleOccupancy', bulleList, period],
        async () => {
            if (!bulleList) return [];
            const bulleOccupancies = await getBulleOccupancyList(20, 0);
            updateDataEveryHour(bulleOccupancies, period);
            return processData(bulleOccupancies, period);
        },
        {
            enabled: !!bulleList,
            retry: false,
        },
    );

    if (isBulleListLoading || isOccupancyLoading) {
        return <p>Chargement...</p>;
    }

    if (error) {
        return <p>Erreur lors du chargement des données</p>;
    }

    return (
        <div className={styles.bulleOverview}>
            <BarChart
                width={1000}
                height={550}
                data={bulleOccupancyData}
                margin={{ top: 20, right: 30, bottom: 5, left: 5 }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis tickFormatter={formatYAxisTick} />
                <Tooltip formatter={formatDuration} />
                <Legend />
                <Bar dataKey="occupation" fill="#8884d8" name="Occupation globale" />
            </BarChart>
        </div>
    );
}
