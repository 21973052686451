import * as React from 'react';
import { useState } from 'react';
import { useQuery } from 'react-query';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
    Button,
    CircularProgress,
    Fab,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { AxiosError } from 'axios';

import AddEventDialog from './AddEventDialog';
import DeleteEventDialog from './DeleteEventDialog';
import UpdateEventDialog from './UpdateEventDialog';
import Event from '../../../models/Event';
import ErrorPage from '../../common/error/ErrorPage';
import { ErrorData } from '../../common/types/types';
import useEventService from '../../services/useEventService';
import useRoomService from '../../services/useRoomService';

import styles from './EventListPage.module.scss';

export default function EventListPage() {
    const { getEventList } = useEventService();
    const { getRoomList } = useRoomService();
    const [openAddEventDialog, setOpenAddEventDialog] = useState<boolean>(false);
    const [openUpdateEventDialog, setOpenUpdateEventDialog] = useState<boolean>(false);
    const [openDeleteEventDialog, setOpenDeleteEventDialog] = useState<boolean>(false);
    const [selectedEvent, setSelectedEvent] = useState<Event>();
    const [hasServerError, setHasServerError] = useState<boolean>(false);

    const {
        data: eventList,
        refetch: refetchEventList,
        isLoading,
    } = useQuery('EventListPage', () => getEventList(20, 0), {
        onError: (error: AxiosError<ErrorData>) => {
            if (error.response && error.response.status === 500) {
                setHasServerError(true);
            }
        },
    });

    const { data: roomList } = useQuery('RoomListPage', () => getRoomList(20, 0));

    enum ButtonOption {
        UPDATE,
        DELETE,
    }

    const handleButtonClick = (event: Event, buttonOption: ButtonOption) => {
        setSelectedEvent(event);
        switch (buttonOption) {
            case ButtonOption.UPDATE:
                setOpenUpdateEventDialog(true);
                break;
            case ButtonOption.DELETE:
                setOpenDeleteEventDialog(true);
                break;
            default:
                break;
        }
    };

    return (
        <div className={styles.container}>
            {isLoading ? (
                <CircularProgress />
            ) : (
                !hasServerError &&
                eventList && (
                    <TableContainer component={Paper}>
                        <Table className={styles.function} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Titre</TableCell>
                                    <TableCell align="right">Salle</TableCell>
                                    <TableCell align="right">Début</TableCell>
                                    <TableCell align="right">Fin</TableCell>
                                    <TableCell align="right" />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {eventList &&
                                    eventList
                                        .filter((event) => event.status !== 'cancelled')
                                        .map((event) => (
                                            <TableRow
                                                key={event.uuid}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {event.name}
                                                </TableCell>
                                                <TableCell align="right">{event.room!.name}</TableCell>
                                                <TableCell align="right">{event.startDatetime}</TableCell>
                                                <TableCell align="right">{event.endDatetime}</TableCell>
                                                <TableCell align="right" padding="none">
                                                    <Button
                                                        title="Update"
                                                        onClick={() => handleButtonClick(event, ButtonOption.UPDATE)}
                                                    >
                                                        <EditIcon />
                                                    </Button>
                                                    <Button
                                                        title="Delete"
                                                        onClick={() => handleButtonClick(event, ButtonOption.DELETE)}
                                                    >
                                                        <DeleteIcon />
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )
            )}
            <Fab
                color="primary"
                aria-label="add"
                onClick={() => setOpenAddEventDialog(true)}
                sx={{ position: 'fixed', bottom: 50 }}
                className={styles.button}
            >
                <AddIcon />
            </Fab>
            <AddEventDialog
                open={openAddEventDialog}
                onSuccess={() => {
                    setOpenAddEventDialog(false);
                    refetchEventList();
                }}
                onClose={() => {
                    setOpenAddEventDialog(false);
                }}
                roomList={roomList!}
            />
            {selectedEvent && (
                <>
                    <UpdateEventDialog
                        open={openUpdateEventDialog}
                        onSuccess={() => {
                            setOpenUpdateEventDialog(false);
                            setSelectedEvent(undefined);
                            refetchEventList();
                        }}
                        onClose={() => {
                            setOpenUpdateEventDialog(false);
                            setSelectedEvent(undefined);
                        }}
                        event={selectedEvent}
                        roomList={roomList!}
                    />
                    <DeleteEventDialog
                        open={openDeleteEventDialog}
                        onSuccess={() => {
                            setOpenDeleteEventDialog(false);
                            setSelectedEvent(undefined);
                            refetchEventList();
                        }}
                        onClose={() => {
                            setOpenDeleteEventDialog(false);
                            setSelectedEvent(undefined);
                        }}
                        event={selectedEvent}
                    />
                </>
            )}
            {hasServerError && <ErrorPage errorCode={500} />}
        </div>
    );
}
