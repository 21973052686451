import useFetchService from './useFetchService';
import Event from '../../models/Event';

export default function useEventService() {
    const { getRequest, postRequest, putRequest, deleteRequest } = useFetchService();

    function getEvent(eventUuid: string) {
        return getRequest<Event>(`events/${eventUuid}`);
    }

    function getEventList(limit: number, offset: number) {
        return getRequest<Event[], { limit: number; offset: number }>('events', { limit, offset });
    }

    function getEventListPeriod(limit: number, offset: number, start: string, end: string) {
        return getRequest<Event[], { limit: number; offset: number; start: string; end: string }>('events', {
            limit,
            offset,
            start,
            end,
        });
    }

    function createEvent(event: Event) {
        return postRequest('events', event);
    }

    function updateEvent(event: Event) {
        return putRequest('events', event);
    }

    function deleteEvent(eventUuid: string) {
        return deleteRequest(`events/${eventUuid}`);
    }

    function createGoogleEvent(event: Event) {
        return postRequest<Event>('events/google', event);
    }

    function getRoomEvents(roomUuid: string) {
        return getRequest<Event[]>(`events/room/${roomUuid}/day`);
    }

    return {
        getEvent,
        getEventList,
        createEvent,
        updateEvent,
        deleteEvent,
        createGoogleEvent,
        getRoomEvents,
        getEventListPeriod,
    };
}
