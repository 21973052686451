import * as React from 'react';
import { useState } from 'react';
import { useQuery } from 'react-query';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Button, ButtonGroup, CircularProgress, Grid, IconButton } from '@mui/material';
import dayjs from 'dayjs';

import BulleOccupancyGauge from './BulleOccupancyGauge';
import BulleOverview from './BulleOverview';
import DashboardCard from './DashboardCard';
import MostOccupiedBulle from './MostOccupiedBulle';
import MostReservedRoom from './MostReservedRoom';
import RoomOccupancyGauge from './RoomOccupancyGauge';
import RoomOverview from './RoomOverview';
import useBullesService from '../../services/useBullesService';
import useRoomService from '../../services/useRoomService';

import styles from './StatPage.module.scss';

type Period = 'daily' | 'weekly' | 'monthly' | 'yearly';

export default function StatPage() {
    const { getRoomList } = useRoomService();
    const { getBulleList } = useBullesService();
    const [selectedPeriod, setSelectedPeriod] = useState<Period>('monthly');
    const [isSallesView, setIsSallesView] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 4;
    const { data: allItems, isLoading } = useQuery(
        isSallesView ? 'roomList' : 'bulleList',
        () => (isSallesView ? getRoomList(1000, 0) : getBulleList(1000, 0)), // Fetch a large number to get all items
        { retry: false },
    );

    const handleNextPage = () => {
        if (allItems && (currentPage + 1) * itemsPerPage < allItems.length) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const paginatedItemList = allItems
        ? allItems.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage)
        : [];
    // Use the duration field directly
    const getSubtitle = (period: Period) => {
        switch (period) {
            case 'daily':
                return "Taux d'occupation journalier";
            case 'weekly':
                return "Taux d'occupation hebdomadaire";
            case 'monthly':
                return "Taux d'occupation mensuel";
            case 'yearly':
                return "Taux d'occupation annuel";
            default:
                return "Taux d'occupation";
        }
    };

    const getPeriodDate = (period: Period) => {
        const d = dayjs().toDate();
        const day = d.getDay();
        const diff = d.getDate() - day + (day === 0 ? -6 : 1);
        const diffAdd = d.getDate() + (7 - day);
        switch (period) {
            case 'daily':
                return dayjs()
                    .toDate()
                    .toLocaleString('fr-FR', { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' });
            case 'weekly':
                return `Semaine du ${new Date(d.setDate(diff)).toLocaleString('fr-FR', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                })} au ${new Date(d.setDate(diffAdd)).toLocaleString('fr-FR', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                })}`;
            case 'monthly':
                return dayjs().toDate().toLocaleString('fr-FR', { month: 'long', year: 'numeric' });
            case 'yearly':
                return dayjs().toDate().toLocaleString('fr-FR', { year: 'numeric' });
            default:
                return dayjs().toDate().toLocaleString('fr-FR', { month: 'long', day: 'numeric', year: 'numeric' });
        }
    };

    return (
        <div className={styles.container}>
            <h1 className={styles.date}> {getPeriodDate(selectedPeriod)}</h1>
            <ButtonGroup variant="outlined" size="large" className={styles.switchButton}>
                <Button onClick={() => setIsSallesView(true)} variant={isSallesView ? 'contained' : 'outlined'}>
                    Salle
                </Button>
                <Button onClick={() => setIsSallesView(false)} variant={!isSallesView ? 'contained' : 'outlined'}>
                    Bulles
                </Button>
            </ButtonGroup>

            <Grid container justifyContent="flex-end">
                <ButtonGroup
                    variant="outlined"
                    color="primary"
                    aria-label="contained primary button group"
                    className={styles.btn_period}
                    size="large"
                >
                    <Button
                        variant={selectedPeriod === 'daily' ? 'contained' : 'outlined'}
                        onClick={() => setSelectedPeriod('daily')}
                    >
                        Jour
                    </Button>
                    <Button
                        variant={selectedPeriod === 'weekly' ? 'contained' : 'outlined'}
                        onClick={() => setSelectedPeriod('weekly')}
                    >
                        Semaine
                    </Button>
                    <Button
                        variant={selectedPeriod === 'monthly' ? 'contained' : 'outlined'}
                        onClick={() => setSelectedPeriod('monthly')}
                    >
                        Mois
                    </Button>
                    <Button
                        variant={selectedPeriod === 'yearly' ? 'contained' : 'outlined'}
                        onClick={() => setSelectedPeriod('yearly')}
                    >
                        Année
                    </Button>
                </ButtonGroup>
            </Grid>
            <Grid container alignItems="center">
                <Grid item>
                    <IconButton onClick={handlePreviousPage} disabled={currentPage === 0}>
                        <ArrowBackIosNewIcon fontSize="large" />
                    </IconButton>
                </Grid>
                <Grid item xs>
                    <Grid container className={styles.grid} columns={5}>
                        {paginatedItemList && !isLoading ? (
                            paginatedItemList.map((item) => (
                                <DashboardCard key={item.uuid} title={item.name} subtitle={getSubtitle(selectedPeriod)}>
                                    {isSallesView ? (
                                        <RoomOccupancyGauge
                                            roomName={item.name!}
                                            key={item.name}
                                            period={selectedPeriod}
                                        />
                                    ) : (
                                        <BulleOccupancyGauge
                                            bulleName={item.name!}
                                            key={item.name}
                                            period={selectedPeriod}
                                        />
                                    )}
                                </DashboardCard>
                            ))
                        ) : (
                            <CircularProgress />
                        )}
                    </Grid>
                </Grid>
                <Grid item>
                    <IconButton
                        onClick={handleNextPage}
                        disabled={allItems && (currentPage + 1) * itemsPerPage >= allItems.length}
                    >
                        <ArrowForwardIosIcon fontSize="large" className={styles.arrowForwardIosIcon} />
                    </IconButton>
                </Grid>
            </Grid>
            <div className={styles.bottomContainer}>
                <Grid item container justifyContent="flex-end">
                    <Grid item>{isSallesView ? <MostReservedRoom /> : <MostOccupiedBulle />}</Grid>
                </Grid>
                <Grid item xs={4}>
                    {isSallesView ? (
                        <RoomOverview period={selectedPeriod} />
                    ) : (
                        <BulleOverview period={selectedPeriod} />
                    )}
                </Grid>
            </div>
        </div>
    );
}
