import Sensor from './Sensor';

export default class Room {
    uuid?: string;

    name?: string;

    googleEmail?: string;

    capacity?: number;

    imageName?: string;

    positionX?: number;

    positionY?: number;

    etage?: number;

    motionSensor?: Sensor;
}
