import * as React from 'react';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { ToastContainer } from 'react-toastify';

import { CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { AxiosError } from 'axios';

import Bulle from '../../../models/Bulle';
import Room from '../../../models/Room';
import { ErrorData } from '../../common/types/types';
import useRoomService from '../../services/useRoomService';

import styles from './RoomListPage.module.scss';

interface RoomListPageProps {
    handleEditItem: (item: Room | Bulle, type: 'room' | 'bulle') => void;
}

export default function RoomListPage({ handleEditItem }: RoomListPageProps) {
    const { getRoomList } = useRoomService();
    const [hasServerError, setHasServerError] = useState<boolean>(false);
    const [openErrorNotification, setOpenErrorNotification] = useState<boolean>(false);
    const { data: roomList, isLoading } = useQuery('RoomListPage', () => getRoomList(20, 0), {
        onError: (error: AxiosError<ErrorData>) => {
            if (error.response && error.response.status === 500) {
                setHasServerError(true);
            }
        },
    });

    useEffect(() => {
        const timer = setTimeout(() => {
            setOpenErrorNotification(false);
        }, 5000);

        return () => {
            clearTimeout(timer);
        };
    }, [openErrorNotification]);

    return (
        <div className={styles.container}>
            {isLoading ? (
                <CircularProgress />
            ) : (
                !hasServerError &&
                roomList && (
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 100 }} aria-label="simple table">
                            <TableBody>
                                {roomList &&
                                    roomList.map((room) => (
                                        <TableRow
                                            key={room.uuid}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            onClick={() => handleEditItem(room, 'room')}
                                            className={styles.row}
                                        >
                                            <TableCell component="th" scope="row">
                                                {room.name}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )
            )}
            <ToastContainer />
        </div>
    );
}
