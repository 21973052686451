import React, { useState, useEffect } from 'react';
import Draggable from 'react-draggable';
import { useQuery, useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

import { CheckCircleOutline } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { CircularProgress, Button, TextField, IconButton, Tabs, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Tab from '@mui/material/Tab';
import axios from 'axios';

import Bulle from '../../../models/Bulle';
import Room from '../../../models/Room';
import Sensor from '../../../models/Sensor';
import useBullesService from '../../services/useBullesService';
import useRoomService from '../../services/useRoomService';
import useSensorService from '../../services/useSensorService';
import BullesListPage from '../bulles/BullesListPage';
import RoomListPage from '../rooms/RoomListPage';

import styles from './OfficeMapDesign.module.scss';

async function saveItem({ item, type }: { item: Room | Bulle; type: 'room' | 'bulle' | undefined }) {
    if (type === 'room') {
        await axios.put('/rooms', item as Room);
    } else if (type === 'bulle') {
        await axios.put('/bulles', item as Bulle);
    }
}

type EditingItem = (Room | Bulle) & { type: 'room' | 'bulle' };

export default function OfficeMapDesign() {
    const { getRoomList } = useRoomService();
    const { getBulleList } = useBullesService();
    const { getSensorList } = useSensorService();

    const { data: roomsData, isLoading: roomsLoading, error: roomsError } = useQuery('rooms', () => getRoomList(50, 0));
    const {
        data: bullesData,
        isLoading: bullesLoading,
        error: bullesError,
        refetch,
    } = useQuery('bulles', () => getBulleList(50, 0));

    const [rooms, setRooms] = useState<Room[]>([]);
    const [bulles, setBulles] = useState<Bulle[]>([]);
    const [currentFloor, setCurrentFloor] = useState(0);
    const [editingItem, setEditingItem] = useState<EditingItem | null>(null);
    const [originalItem, setOriginalItem] = useState<EditingItem | null>(null);
    const [tabValue, setTabValue] = useState('1');
    const [isEditingName, setIsEditingName] = useState<boolean>(false);
    const [isAddingBulle, setIsAddingBulle] = useState<boolean>(false);

    const handleCancel = () => {
        if (originalItem) {
            if (originalItem.type === 'room') {
                setRooms((prevRooms) =>
                    prevRooms.map((room) => (room.uuid === originalItem.uuid ? originalItem : room)),
                );
            } else if (originalItem.type === 'bulle') {
                setBulles((prevBulles) =>
                    prevBulles.map((bulle) => (bulle.uuid === originalItem.uuid ? originalItem : bulle)),
                );
            }
        }
        setEditingItem(null);
    };

    async function createBulle(bulle: Bulle) {
        await axios.post('/bulles', bulle).then(() => {
            refetch();
            handleCancel();
        });
    }

    const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };
    const { data: sensorList } = useQuery('SensorListPage', () => getSensorList(20, 0));

    const mutation = useMutation(saveItem, {
        onSuccess: () => {},
    });

    useEffect(() => {
        if (roomsData) {
            setRooms(roomsData);
        }
        if (bullesData) {
            setBulles(bullesData);
        }
    }, [roomsData, bullesData]);

    useEffect(() => {
        if (isAddingBulle) {
            setEditingItem((prevItem) => {
                if (!prevItem) return prevItem;

                const updatedItem = {
                    ...prevItem,
                    positionX: 800,
                    positionY: -300,
                };

                setBulles((prevBulles) =>
                    prevBulles.map((bulle) => (bulle.uuid === updatedItem.uuid ? updatedItem : bulle)),
                );

                return updatedItem;
            });
            setBulles((prevBulles) => [...prevBulles, editingItem as Bulle]);
        }
    }, [isAddingBulle]);

    const handleFloorChange = (newFloor: number) => {
        if (newFloor >= 0 && newFloor <= 1) {
            setCurrentFloor(newFloor);
        }
    };

    const handleEditItem = (item: Room | Bulle | null, type: 'room' | 'bulle') => {
        if (item == null) {
            setEditingItem(null);
            setOriginalItem(null);
            item = {
                ...editingItem,
                name: 'Bulle',
                capacity: 0,
                screen: false,
                motionSensor: undefined,
                etage: 0,
                uuid: undefined,
            };
        }
        handleFloorChange(item.etage as number);
        setEditingItem({ ...item, type });
        setOriginalItem({ ...item, type });
    };

    const handleSave = () => {
        mutation.mutate({ item: editingItem as Room | Bulle, type: editingItem?.type });
        setEditingItem(null);
    };
    const handleAdd = () => {
        const { capacity, etage, motionSensor, name, positionX, positionY, screen } = editingItem as Bulle;
        const newBulle: Bulle = { capacity, etage, motionSensor, name, positionX, positionY, screen };
        createBulle(newBulle).then(() => toast.success('Bulle ajoutée'));
        setIsAddingBulle(false);
    };

    if (roomsLoading || bullesLoading) {
        return <CircularProgress />;
    }

    if (roomsError || bullesError) {
        return <div>Erreur lors du chargement des salles ou des bulles</div>;
    }

    const textFieldFonction = (prevItem: EditingItem, value: string, field: string) => {
        if (!prevItem) return prevItem;

        let updatedItem: EditingItem = prevItem;

        switch (field) {
            case 'googleEmail':
                updatedItem = {
                    ...prevItem,
                    googleEmail: value,
                };
                break;
            case 'positionX':
                updatedItem = {
                    ...prevItem,
                    positionX: parseInt(value, 10),
                };
                break;
            case 'positionY':
                updatedItem = {
                    ...prevItem,
                    positionY: parseInt(value, 10),
                };
                break;
            case 'capacity':
                updatedItem = {
                    ...prevItem,
                    capacity: parseInt(value, 10),
                };
                break;
            case 'image':
                updatedItem = {
                    ...prevItem,
                    imageName: value,
                };
                break;
            case 'etage':
                updatedItem = {
                    ...prevItem,
                    etage: parseInt(value, 10),
                };
                handleFloorChange(parseInt(value, 10));
                break;
            case 'name':
                updatedItem = {
                    ...prevItem,
                    name: value,
                };
                break;
            case 'screen':
                updatedItem = {
                    ...prevItem,
                    screen: value === 'true',
                };
                break;
            default:
                break;
        }

        if (!prevItem) return prevItem;

        if (prevItem.type === 'room') {
            setRooms((prevRooms) => prevRooms.map((room) => (room.uuid === updatedItem.uuid ? updatedItem : room)));
        } else if (prevItem.type === 'bulle') {
            setBulles((prevBulles) =>
                prevBulles.map((bulle) => (bulle.uuid === updatedItem.uuid ? updatedItem : bulle)),
            );
        }
        return updatedItem;
    };

    function verifySubmit(): boolean {
        if (editingItem !== undefined && editingItem !== null) {
            if (
                editingItem.capacity !== undefined &&
                editingItem.name?.trim() !== '' &&
                editingItem.etage !== undefined
            ) {
                if (editingItem.capacity >= 1 && (editingItem.etage === 0 || editingItem.etage === 1)) {
                    if (editingItem.type === 'bulle') {
                        return editingItem.motionSensor !== undefined;
                    }
                    return true;
                }
            }
        }
        return false;
    }

    return (
        <div className={styles.container} id="edit">
            <div className={styles.floorName}>{currentFloor === 0 ? 'Open Space' : '1er Étage'} </div>

            <div className={styles.arrowAndMapContainer}>
                <div className={styles.arrowContainer}>
                    <Button
                        className={styles.arrow}
                        onClick={() => handleFloorChange(currentFloor + 1)}
                        disabled={currentFloor === 1}
                    >
                        <ArrowUpwardIcon style={{ color: 'black' }} />
                    </Button>
                    <Button
                        className={styles.arrow}
                        onClick={() => handleFloorChange(currentFloor - 1)}
                        disabled={currentFloor === 0}
                    >
                        <ArrowDownwardIcon style={{ color: 'black' }} />
                    </Button>
                </div>
                <div className={styles.mapWrapper}>
                    <TransformWrapper
                        initialScale={1}
                        minScale={1}
                        maxScale={1}
                        centerOnInit
                        wheel={{ disabled: true }}
                        doubleClick={{ disabled: true }}
                        pinch={{ disabled: true }}
                        panning={{ disabled: true }}
                    >
                        <TransformComponent>
                            <div className={styles.mapContainer}>
                                <img
                                    src={currentFloor === 0 ? '/Plan%20Taki.png' : '/etage.png'}
                                    alt="Office Map"
                                    className={styles.mapImage}
                                    draggable={false}
                                />
                                {rooms.map((room) => (
                                    <Draggable
                                        key={room.uuid}
                                        bounds="parent"
                                        onStart={() => {
                                            handleEditItem(room, 'room');
                                        }}
                                        onDrag={(_e: any, ui: any) => {
                                            if (editingItem) {
                                                editingItem.positionX = ui.x;
                                                editingItem.positionY = ui.y;
                                            }
                                        }}
                                        defaultPosition={{ x: room.positionX!, y: room.positionY! }}
                                    >
                                        <div
                                            key={room.uuid}
                                            className={`${styles.roomDot} ${
                                                editingItem?.uuid === room.uuid ? styles.activeDot : ''
                                            }`}
                                            data-room-name={room.name}
                                            data-room-status="editable"
                                            role="button"
                                            tabIndex={0}
                                            style={{ display: currentFloor === room.etage ? 'inherit' : 'none' }}
                                        >
                                            <span className={styles.tooltip}>{room.name}</span>
                                        </div>
                                    </Draggable>
                                ))}
                                {bulles.map((bulle) => (
                                    <Draggable
                                        key={bulle.uuid}
                                        bounds="parent"
                                        onStart={() => {
                                            handleEditItem(bulle, 'bulle');
                                        }}
                                        onDrag={(_e: any, ui: any) => {
                                            if (editingItem) {
                                                editingItem.positionX = ui.x;
                                                editingItem.positionY = ui.y;
                                            }
                                        }}
                                        defaultPosition={{ x: bulle.positionX!, y: bulle.positionY! }}
                                    >
                                        <div
                                            key={bulle.uuid}
                                            className={`${styles.bulleDot} ${
                                                editingItem?.uuid === bulle.uuid ? styles.activeDot : ''
                                            }`}
                                            data-bulle-name={bulle.name}
                                            data-bulle-status="editable"
                                            role="button"
                                            tabIndex={0}
                                            style={{ display: currentFloor === bulle.etage ? 'inherit' : 'none' }}
                                        >
                                            <span className={styles.bulleTooltip}>{bulle.name}</span>
                                        </div>
                                    </Draggable>
                                ))}
                            </div>
                        </TransformComponent>
                    </TransformWrapper>
                </div>
                <Box className={styles.tabContainer}>
                    <TabContext value={tabValue}>
                        <Tabs
                            onChange={handleChange}
                            orientation="horizontal"
                            variant="fullWidth"
                            className={styles.headerSelect}
                            value={tabValue}
                        >
                            <Tab
                                label="Salles "
                                value="1"
                                icon={<RoomPreferencesIcon />}
                                iconPosition="start"
                                disabled={tabValue === '1'}
                                className={styles.selectedTab}
                                onClick={() => {
                                    handleCancel();
                                }}
                            />
                            <Tab
                                label="Bulles"
                                value="2"
                                icon={<BubbleChartIcon />}
                                iconPosition="end"
                                disabled={tabValue === '2'}
                                className={styles.selectedTab}
                                onClick={handleCancel}
                            />
                        </Tabs>
                        <div className={styles.tabContent}>
                            <div className={styles.viewRows}>
                                <TabPanel value="1">
                                    <RoomListPage handleEditItem={handleEditItem} />
                                </TabPanel>
                                <TabPanel value="2">
                                    <Button
                                        className={styles.addBulleButton}
                                        onClick={() => {
                                            setIsAddingBulle(true);
                                            handleEditItem(null, 'bulle');
                                        }}
                                    >
                                        <AddIcon sx={{ marginBottom: '5px' }} /> Ajouter une Bulle
                                    </Button>
                                    <BullesListPage
                                        bulles={bulles}
                                        handleEditItem={handleEditItem}
                                        setIsAddingBulle={setIsAddingBulle}
                                        handleCancel={handleCancel}
                                        setBulles={setBulles}
                                    />
                                </TabPanel>
                            </div>
                            <div className={styles.sidebarEditor}>
                                {editingItem && (
                                    <div className={`${styles.sidebar} ${editingItem ? styles.open : ''}`}>
                                        <div className={styles.sidebarHeader}>
                                            {!isEditingName && (
                                                <Typography className={styles.roomName}>
                                                    {editingItem.name}
                                                    <IconButton size="small" onClick={() => setIsEditingName(true)}>
                                                        <EditIcon color="info" fontSize="small" />
                                                    </IconButton>
                                                </Typography>
                                            )}
                                            {isEditingName && (
                                                <>
                                                    <TextField
                                                        label="Nom"
                                                        value={editingItem.name}
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        margin="normal"
                                                        onChange={(e) => {
                                                            setEditingItem(
                                                                textFieldFonction(editingItem, e.target.value, 'name'),
                                                            );
                                                        }}
                                                    />
                                                    <IconButton onClick={() => setIsEditingName(false)}>
                                                        <CheckCircleOutline color="success" />
                                                    </IconButton>
                                                </>
                                            )}
                                            <IconButton onClick={handleCancel} size="large">
                                                <CloseIcon color="error" fontSize="large" />
                                            </IconButton>
                                        </div>
                                        <div className={styles.sidebarContent}>
                                            <TextField
                                                label="Capacité"
                                                value={editingItem.capacity}
                                                variant="outlined"
                                                type="number"
                                                InputProps={{ inputProps: { min: 1, max: 99 } }}
                                                fullWidth
                                                required
                                                margin="normal"
                                                onChange={(e) => {
                                                    setEditingItem(
                                                        textFieldFonction(editingItem, e.target.value, 'capacity'),
                                                    );
                                                }}
                                            />
                                            {editingItem.type === 'room' && (
                                                <TextField
                                                    label="Nom de l'image"
                                                    value={
                                                        (editingItem as Room).imageName
                                                            ? (editingItem as Room).imageName
                                                            : 'conference_room.jpg'
                                                    }
                                                    variant="outlined"
                                                    fullWidth
                                                    margin="normal"
                                                    onChange={(e) => {
                                                        setEditingItem(
                                                            textFieldFonction(editingItem, e.target.value, 'image'),
                                                        );
                                                    }}
                                                />
                                            )}

                                            {editingItem.type === 'bulle' && (
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={(editingItem as Bulle).screen}
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    setEditingItem(
                                                                        textFieldFonction(
                                                                            editingItem,
                                                                            'true',
                                                                            'screen',
                                                                        ),
                                                                    );
                                                                } else {
                                                                    setEditingItem(
                                                                        textFieldFonction(
                                                                            editingItem,
                                                                            'false',
                                                                            'screen',
                                                                        ),
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                    }
                                                    label="Ecran"
                                                />
                                            )}
                                            <TextField
                                                label="Etage"
                                                type="number"
                                                value={editingItem.etage}
                                                InputProps={{ inputProps: { min: 0, max: 1 } }}
                                                variant="outlined"
                                                fullWidth
                                                required
                                                margin="normal"
                                                onChange={(e) => {
                                                    setEditingItem(
                                                        textFieldFonction(editingItem, e.target.value, 'etage'),
                                                    );
                                                }}
                                            />
                                            <Autocomplete
                                                className={styles.sidebarAutocomplete}
                                                options={sensorList!}
                                                defaultValue={null}
                                                value={editingItem.motionSensor}
                                                getOptionLabel={(sensor: Sensor) => sensor.friendlyName!}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Capteur"
                                                        required={editingItem?.type === 'bulle'}
                                                    />
                                                )}
                                                onChange={(_e, value) => {
                                                    if (!editingItem) return editingItem;
                                                    let updatedItem: EditingItem = editingItem;
                                                    updatedItem = {
                                                        ...editingItem,
                                                        motionSensor: value as Sensor,
                                                    };
                                                    editingItem.motionSensor = value ?? undefined;
                                                    if (!editingItem) return editingItem;
                                                    if (editingItem.type === 'room') {
                                                        setRooms((prevRooms) =>
                                                            prevRooms.map((room) =>
                                                                room.uuid === updatedItem.uuid ? updatedItem : room,
                                                            ),
                                                        );
                                                    } else if (editingItem.type === 'bulle') {
                                                        setBulles((prevBulles) =>
                                                            prevBulles.map((bulle) =>
                                                                bulle.uuid === updatedItem.uuid ? updatedItem : bulle,
                                                            ),
                                                        );
                                                    }
                                                    return updatedItem;
                                                }}
                                            />
                                            {!editingItem.uuid && (
                                                <Button
                                                    className={styles.sidebarButton}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleAdd}
                                                    disabled={!verifySubmit()}
                                                >
                                                    Ajouter la bulle
                                                </Button>
                                            )}
                                            <Typography variant="caption"> * : Champs obligatoires</Typography>
                                            {editingItem.uuid && (
                                                <Button
                                                    className={styles.sidebarButton}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleSave}
                                                    disabled={!verifySubmit()}
                                                >
                                                    Sauvegarder les modifications
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </TabContext>
                </Box>
            </div>
        </div>
    );
}
