import useFetchService from './useFetchService';
import RoomStatDto from '../../models/RoomStatDto';

export default function useStatService() {
    const { getRequest } = useFetchService();

    function getRoomOccupancy(roomName: string, period: string, limit: number, offset: number) {
        return getRequest<RoomStatDto, { roomName: string; period: string; limit: number; offset: number }>(
            `stats/${roomName}/occupancy`,
            {
                roomName,
                period,
                limit,
                offset,
            },
        );
    }

    return { getRoomOccupancy };
}
