import * as React from 'react';
import { useMutation } from 'react-query';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import Event from '../../../models/Event';
import useEventService from '../../services/useEventService';

export default function DeleteEventDialog({
    open,
    onSuccess,
    onClose,
    event,
}: {
    open: boolean;
    onSuccess: () => void;
    onClose: () => void;
    event: Event;
}) {
    const { deleteEvent } = useEventService();

    const deleteEventQuery = useMutation(() => deleteEvent(event.uuid!), {
        onSuccess: () => onSuccess(),
    });

    return (
        <Dialog open={open}>
            <DialogTitle>Supprimer</DialogTitle>
            <DialogContent>Etes-vous sur de vouloir supprimer l&apos;évènement ?</DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button variant="contained" onClick={() => deleteEventQuery.mutate()}>
                    Supprimer
                </Button>
            </DialogActions>
        </Dialog>
    );
}
