import { useKeycloak } from '@react-keycloak/web';
import React from 'react';

import LogoutIcon from '@mui/icons-material/Logout';
import { Button } from '@mui/material';

import styles from './LogoutBtn.module.scss';

export default function LogoutBtn() {
    const { keycloak } = useKeycloak();

    return (
        <div>
            {keycloak?.authenticated && (
                <Button
                    startIcon={<LogoutIcon />}
                    size="large"
                    onClick={async () => {
                        await keycloak.logout();
                    }}
                    className={styles.button}
                >
                    Logout
                </Button>
            )}
        </div>
    );
}
