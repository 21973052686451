import useFetchService from './useFetchService';
import UserDTO from '../../models/UserDTO';

export default function useUserService() {
    const { getRequest } = useFetchService();

    function getUsers() {
        return getRequest<UserDTO[]>('api/takima/accounts');
    }

    return {
        getUsers,
    };
}
