import useFetchService from './useFetchService';
import Room from '../../models/Room';

export default function useRoomService() {
    const { getRequest, postRequest, putRequest, deleteRequest } = useFetchService();

    function getRoom(roomUuid: string) {
        return getRequest<Room>(`rooms/${roomUuid}`);
    }

    function getRoomList(limit: number, offset: number) {
        return getRequest<Room[], { limit: number; offset: number }>('rooms', { limit, offset });
    }

    function createRoom(room: Room) {
        return postRequest('rooms', room);
    }

    function updateRoom(room: Room) {
        return putRequest('rooms', room);
    }

    function deleteRoom(roomUuid: string) {
        return deleteRequest(`rooms/${roomUuid}`);
    }

    function fetchRoomStatus(roomName: string) {
        return getRequest<String>(`/stats/${roomName}/current-status`);
    }

    function fetchRoomSensorStatus(roomId: string) {
        return getRequest<String>(`/rooms/${roomId}/sensor-status`);
    }

    return { getRoom, getRoomList, createRoom, updateRoom, deleteRoom, fetchRoomStatus, fetchRoomSensorStatus };
}
