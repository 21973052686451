import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { Typography, Box, Table, TableBody, TableCell, TableHead, TableRow, Card } from '@mui/material';

import Event from '../../../models/Event';
import useEventService from '../../services/useEventService';

import styles from './StatPage.module.scss';

type RoomStats = {
    name: string;
    reservationCount: number;
    totalTimeSpent: number;
};

function MostReservedRoom() {
    const { getEventList } = useEventService();
    const { data: eventList } = useQuery('EventListPage', () => getEventList(1000, 0));
    const [roomStats, setRoomStats] = useState<RoomStats[]>([]);

    useEffect(() => {
        if (eventList) {
            const roomStatsMap: { [key: string]: RoomStats } = {};

            eventList
                .filter((event: Event) => event.status !== 'cancelled')
                .forEach((event: Event) => {
                    if (event.room && event.room.name && event.startDatetime && event.endDatetime) {
                        const roomName = event.room.name;
                        const startTime = new Date(event.startDatetime).getTime();
                        const endTime = new Date(event.endDatetime).getTime();
                        const duration = (endTime - startTime) / (1000 * 60 * 60);

                        if (!roomStatsMap[roomName]) {
                            roomStatsMap[roomName] = { name: roomName, reservationCount: 0, totalTimeSpent: 0 };
                        }

                        roomStatsMap[roomName].reservationCount += 1;
                        roomStatsMap[roomName].totalTimeSpent += duration;
                    }
                });

            const sortedRoomStats = Object.values(roomStatsMap).sort((a, b) => b.totalTimeSpent - a.totalTimeSpent);
            setRoomStats(sortedRoomStats);
        }
    }, [eventList]);

    const formatTime = (totalTime: number) => {
        const hours = Math.floor(totalTime);
        const minutes = Math.round((totalTime - hours) * 60);
        return `${hours}h ${minutes} min`;
    };

    return (
        <Card className={styles.room_stat}>
            <Typography variant="h5" component="div" sx={{ padding: '16px' }}>
                Les salles les plus réservées
            </Typography>
            <Box sx={{ overflow: 'auto', width: { xs: '400px', sm: 'auto' } }}>
                <Table aria-label="room stats table" sx={{ whiteSpace: 'nowrap', mt: 2 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography variant="subtitle2" fontWeight={600}>
                                    Nom
                                </Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Typography variant="subtitle2" fontWeight={600}>
                                    Nombre de réservations
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography variant="subtitle2" fontWeight={600}>
                                    Temps total passé
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {roomStats.map((room) => (
                            <TableRow key={room.name}>
                                <TableCell>
                                    <Typography sx={{ fontSize: '15px', fontWeight: '500' }}>{room.name}</Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="subtitle2" fontWeight={600}>
                                        {room.reservationCount}
                                    </Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography variant="h6">{formatTime(room.totalTimeSpent)}</Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        </Card>
    );
}

export default MostReservedRoom;
