import { ReactKeycloakProvider } from '@react-keycloak/web';
import React, { StrictMode } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Route, Routes } from 'react-router-dom';

import { ThemeProvider } from '@mui/material';
import axios from 'axios';

import AppLayout from './components/common/appLayout/AppLayout';
import ErrorPage from './components/common/error/ErrorPage';
import theme from './components/common/Themes';
import EventListPage from './components/pages/events/EventListPage';
import HomePage from './components/pages/home/HomePage';
import OfficeMap from './components/pages/maps/OfficeMap';
import OfficeMapDesign from './components/pages/maps/OfficeMapDesign';
import RoomDetails from './components/pages/salles/RoomDetails';
import RoomDetailsGuest from './components/pages/salles/RoomDetailsGuest';
import SallePage from './components/pages/salles/SallePage';
import SensorListPage from './components/pages/sensors/SensorListPage';
import StatPage from './components/pages/stat/StatPage';
import keycloakInstance from './components/security/Keycloak';
import KeycloakAuthenticator from './components/security/KeycloakAuthenticator';
import ProtectedComponent from './components/security/ProtectedComponent';
import { SidebarProvider } from './contexts/SidebarContext';
import Role from './models/Role';

const queryClient = new QueryClient();
axios.defaults.baseURL = Config.apiBaseUrl;

function App() {
    return (
        <ReactKeycloakProvider
            authClient={keycloakInstance}
            initOptions={{
                checkLoginIframe: false,
            }}
        >
            <StrictMode>
                <SidebarProvider>
                    <AppLayout>
                        <QueryClientProvider client={queryClient}>
                            <ThemeProvider theme={theme}>
                                <Routes>
                                    <Route
                                        path="/"
                                        element={
                                            <KeycloakAuthenticator>
                                                <ProtectedComponent requiredRole={Role.USER}>
                                                    <HomePage />
                                                </ProtectedComponent>
                                            </KeycloakAuthenticator>
                                        }
                                    />
                                    <Route
                                        path="/event"
                                        element={
                                            <KeycloakAuthenticator>
                                                <ProtectedComponent requiredRole={Role.ADMIN}>
                                                    <EventListPage />
                                                </ProtectedComponent>
                                            </KeycloakAuthenticator>
                                        }
                                    />
                                    <Route
                                        path="/sensors"
                                        element={
                                            <KeycloakAuthenticator>
                                                <ProtectedComponent requiredRole={Role.ADMIN}>
                                                    <SensorListPage />
                                                </ProtectedComponent>
                                            </KeycloakAuthenticator>
                                        }
                                    />
                                    <Route
                                        path="/stat"
                                        element={
                                            <KeycloakAuthenticator>
                                                <ProtectedComponent requiredRole={Role.ADMIN}>
                                                    <StatPage />
                                                </ProtectedComponent>
                                            </KeycloakAuthenticator>
                                        }
                                    />
                                    <Route path="/reservation" element={<SallePage />} />
                                    <Route
                                        path="/roomdetails/:roomUuid/:roomName"
                                        element={
                                            <KeycloakAuthenticator>
                                                <ProtectedComponent requiredRole={Role.USER}>
                                                    <RoomDetails />
                                                </ProtectedComponent>
                                            </KeycloakAuthenticator>
                                        }
                                    />
                                    <Route
                                        path="/map"
                                        element={
                                            <KeycloakAuthenticator>
                                                <ProtectedComponent requiredRole={Role.USER}>
                                                    <OfficeMap />
                                                </ProtectedComponent>
                                            </KeycloakAuthenticator>
                                        }
                                    />
                                    <Route
                                        path="/map-design"
                                        element={
                                            <KeycloakAuthenticator>
                                                <ProtectedComponent requiredRole={Role.ADMIN}>
                                                    <OfficeMapDesign />
                                                </ProtectedComponent>
                                            </KeycloakAuthenticator>
                                        }
                                    />
                                    <Route
                                        path="/roomdetails-guest/:roomUuid/:roomName"
                                        element={<RoomDetailsGuest />}
                                    />

                                    <Route path="*" element={<ErrorPage errorCode={404} />} />
                                </Routes>
                            </ThemeProvider>
                        </QueryClientProvider>
                    </AppLayout>
                </SidebarProvider>
            </StrictMode>
        </ReactKeycloakProvider>
    );
}

export default App;
