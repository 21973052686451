import { useKeycloak } from '@react-keycloak/web';
import React from 'react';

import PersonIcon from '@mui/icons-material/Person';

import styles from './Avatar.module.scss';

export default function Avatar() {
    const { keycloak } = useKeycloak();

    const preferredUsername = keycloak.authenticated ? keycloak?.tokenParsed?.preferred_username : 'Invité';

    return (
        <div className={styles.avatar}>
            <PersonIcon />
            {preferredUsername}
        </div>
    );
}
