import React, { createContext, useState, useContext, useMemo } from 'react';

interface SidebarContextProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SidebarContext = createContext<SidebarContextProps | undefined>(undefined);

interface SidebarProviderProps {
    children: React.ReactNode;
}

export function SidebarProvider({ children }: SidebarProviderProps) {
    const [open, setOpen] = useState(false);

    const value = useMemo(() => ({ open, setOpen }), [open]);

    return <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>;
}

export const useSidebarContext = () => {
    const context = useContext(SidebarContext);
    if (context === undefined) {
        throw new Error('useSidebarContext must be used within a SidebarProvider');
    }
    return context;
};
