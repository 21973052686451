import { useKeycloak } from '@react-keycloak/web';
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import AssessmentIcon from '@mui/icons-material/Assessment';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DesignServicesRoundedIcon from '@mui/icons-material/DesignServicesRounded';
import LoginIcon from '@mui/icons-material/Login';
import MapIcon from '@mui/icons-material/Map';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import MenuIcon from '@mui/icons-material/Menu';
import SensorsIcon from '@mui/icons-material/Sensors';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';

import { useSidebarContext } from '../../../../contexts/SidebarContext';
import Role from '../../../../models/Role';
import getBookingRole from '../../../../utils/RoleDomain';
import Avatar from '../../avatar/Avatar';
import LogoutBtn from '../../buttons/logoutButton/LogoutBtn';

import styles from './SideBar.module.scss';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
    padding: 0,
    marginTop: 30,
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    backgroundColor: 'transparent',
    boxShadow: 'none',
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

interface MenuItem {
    title: string;
    url: string;
    icon: JSX.Element;
}

export default function SideBar() {
    const location = useLocation();
    const [selectedItem, setSelectedItem] = useState<string | null>(null);
    const { open, setOpen } = useSidebarContext();
    const { keycloak } = useKeycloak();

    let MENU: MenuItem[];
    if (keycloak.authenticated) {
        if (getBookingRole(keycloak.realmAccess?.roles!).includes('admin' as Role)) {
            MENU = [
                { title: 'Capteur', url: '/sensors', icon: <SensorsIcon /> },
                { title: 'Evénement', url: '/event', icon: <CalendarMonthIcon /> },
                { title: 'Statistiques', url: '/stat', icon: <AssessmentIcon /> },
                { title: 'Réservation', url: '/reservation', icon: <MeetingRoomIcon /> },
                { title: 'Editeur', url: '/map-design', icon: <DesignServicesRoundedIcon /> },
                { title: 'Carte', url: '/map', icon: <MapIcon /> },
            ];
        } else {
            MENU = [
                { title: 'Réservation', url: '/reservation', icon: <MeetingRoomIcon /> },
                { title: 'Carte', url: '/map', icon: <MapIcon /> },
            ];
        }
    } else {
        MENU = [
            { title: 'Réservation', url: '/reservation', icon: <MeetingRoomIcon /> },
            { title: 'Connection', url: '/', icon: <LoginIcon /> },
        ];
    }

    useEffect(() => {
        const currentPath = location.pathname;
        const menuItem = MENU.find((item) => currentPath.startsWith(item.url));
        if (menuItem) {
            setSelectedItem(menuItem.title);
        }
    }, [location, MENU]);

    const handleDrawerOpen = () => {
        setOpen(true);
        document.body.classList.add('sidebar-open');
    };

    const handleDrawerClose = () => {
        setOpen(false);
        document.body.classList.remove('sidebar-open');
    };

    const handleItemClick = (title: string) => {
        setSelectedItem(title);
        handleDrawerClose();
    };

    const handleLogoClick = () => {
        setSelectedItem(null);
        handleDrawerClose();
    };

    const toggleDrawer = (newOpen: boolean) => () => {
        setOpen(newOpen);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="absolute" open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            mr: 2,
                            ...(open && { display: 'none' }),
                            color: 'rgba(0, 0, 0, 0.54)',
                        }}
                    >
                        <MenuIcon
                            sx={{
                                width: 35,
                                height: 35,
                            }}
                        />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer
                className={styles.sidebar}
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                    },
                }}
                variant="temporary"
                anchor="left"
                open={open}
                onClose={toggleDrawer(false)}
            >
                <div>
                    <DrawerHeader>
                        <IconButton onClick={handleDrawerClose}>
                            <ChevronLeftIcon
                                sx={{
                                    width: 35,
                                    height: 35,
                                }}
                            />
                        </IconButton>
                    </DrawerHeader>
                    <Divider />
                    <div className={styles.div}>
                        <Link to="/" className={styles.link} onClick={handleLogoClick}>
                            <img src="/logo.ico" alt="Logo" className={styles.logo} />
                            <span
                                role="button"
                                tabIndex={0}
                                className={styles.span}
                                onClick={handleDrawerClose}
                                onKeyDown={handleDrawerClose}
                            >
                                BOOKING
                            </span>
                        </Link>
                    </div>
                    <Divider />
                    <List>
                        {MENU.map((menu: MenuItem) => (
                            <ListItem
                                key={menu.title}
                                className={`${styles.listItem} ${
                                    selectedItem === menu.title ? styles.selectedItem : ''
                                }`}
                            >
                                <Link
                                    to={menu.url}
                                    className={styles.listItemLink}
                                    onClick={() => handleItemClick(menu.title)}
                                >
                                    <ListItemIcon className={styles.listicon}>{menu.icon}</ListItemIcon>
                                    <ListItemText primary={menu.title} classes={{ primary: styles.listItemText }} />
                                </Link>
                            </ListItem>
                        ))}
                    </List>
                </div>
                <div className={styles.avatarContainer}>
                    <Divider />
                    <Box className={styles.avatar}>
                        <Avatar />
                    </Box>
                    <Box className={styles.logoutButton}>
                        <LogoutBtn />
                    </Box>
                </div>
            </Drawer>
            <Main open={open}>
                <DrawerHeader />
            </Main>
        </Box>
    );
}
