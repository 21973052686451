import { useKeycloak } from '@react-keycloak/web';
import React from 'react';

import Role from '../../models/Role';
import getBookingRole from '../../utils/RoleDomain';
import ErrorPage from '../common/error/ErrorPage';
import LoadingSpinner from '../common/LoadingSpinner';

interface ProtectedComponentProps {
    children: JSX.Element;
    requiredRole: Role;
}

export default function ProtectedComponent({ requiredRole, children }: ProtectedComponentProps) {
    const { keycloak } = useKeycloak();
    const roles = keycloak?.realmAccess?.roles;

    if (!roles) {
        return <LoadingSpinner />;
    }

    if (roles && getBookingRole(roles).includes(requiredRole)) {
        return children;
    }

    return <ErrorPage errorCode={403} />;
}
