import { useKeycloak } from '@react-keycloak/web';

import axios from 'axios';

export default function useFetchService() {
    const { keycloak, initialized } = useKeycloak();

    axios.interceptors.request.use(async (config) => {
        if (initialized && keycloak.authenticated) {
            config.headers.Authorization = `Bearer ${keycloak.token}`;
        }
        return config;
    });

    function getRequest<T = void, P = void>(path: string, params?: P) {
        return axios.get<T>(path, { params }).then((axiosResponse) => axiosResponse.data);
    }

    function postRequest<D = void, T = void>(path: string, data?: D) {
        return axios.post<T>(path, data).then((axiosResponse) => axiosResponse.data);
    }

    function patchRequest<D = void, T = void>(path: string, data?: D) {
        return axios.patch<T>(path, data).then((axiosResponse) => axiosResponse.data);
    }

    function putRequest<D = void, T = void>(path: string, data?: D) {
        return axios.put<T>(path, data).then((axiosResponse) => axiosResponse.data);
    }

    function deleteRequest<T = void>(path: string) {
        return axios.delete<T>(path).then((axiosResponse) => axiosResponse.data);
    }

    return { getRequest, postRequest, patchRequest, putRequest, deleteRequest };
}
