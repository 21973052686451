import * as React from 'react';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    TextField,
} from '@mui/material';
import { AxiosError } from 'axios';

import Sensor from '../../../models/Sensor';
import { ErrorData } from '../../common/types/types';
import ErrorNotification from '../../notification/ErrorNotification';
import useSensorService from '../../services/useSensorService';

export default function UpdateSensorDialog({
    open,
    onSuccess,
    onClose,
    selectedSensor,
}: {
    open: boolean;
    onSuccess: () => void;
    onClose: () => void;
    selectedSensor: Sensor;
}) {
    const [idSensor, setIdSensor] = useState<string>(selectedSensor.sensorId!);
    const [statSensor, setStatSensor] = useState<string>(selectedSensor.state!);
    const [deviceClassSensor, setDeviceClassSensor] = useState<string>(selectedSensor.deviceClass!);
    const [freindlyNameSensor, setFreindlyNameSensor] = useState<string>(selectedSensor.friendlyName!);
    const [openErrorNotification, setOpenErrorNotification] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>('');

    const resetForm = () => {
        setIdSensor('');
        setStatSensor('');
        setDeviceClassSensor('');
        setFreindlyNameSensor('');
        setOpenErrorNotification(false);
        setErrorMessage('');
    };

    const { updateSensor } = useSensorService();

    const handleClose = () => {
        resetForm();
        onClose();
    };

    const updateSensorQuery = useMutation(
        'Update Sensor',
        () =>
            updateSensor({
                id: selectedSensor.id,
                sensorId: idSensor,
                state: statSensor,
                deviceClass: deviceClassSensor,
                friendlyName: freindlyNameSensor,
            }),
        {
            onSuccess: () => {
                resetForm();
                onSuccess();
                toast.success('Modification(s) validée(s)');
            },
            onError: (error: AxiosError<ErrorData>) => {
                setErrorMessage(error.response?.data.body.message);
                setOpenErrorNotification(true);
            },
        },
    );

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Modifier une salle</DialogTitle>
            <DialogContent>
                <TextField
                    id="outlined-required"
                    required
                    label="ID du Capteur"
                    variant="outlined"
                    value={idSensor}
                    onChange={(event) => setIdSensor(event.target.value)}
                    style={{ marginTop: '7px' }}
                />
            </DialogContent>
            <DialogContent>
                <TextField
                    id="outlined-required"
                    required
                    label="Classe du Capteur"
                    variant="outlined"
                    value={deviceClassSensor}
                    onChange={(event) => setDeviceClassSensor(event.target.value)}
                />
            </DialogContent>
            <DialogContent>
                <TextField
                    id="outlined-required"
                    required
                    label="Nom du Capteur"
                    variant="outlined"
                    value={freindlyNameSensor}
                    onChange={(event) => setFreindlyNameSensor(event.target.value)}
                />
            </DialogContent>
            <FormControl style={{ marginLeft: '31px' }}>
                <FormLabel id="demo-row-radio-buttons-group-label"> État du capteur</FormLabel>
                <RadioGroup
                    value={statSensor}
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={(event) => setStatSensor(event.target.value)}
                >
                    <FormControlLabel value="on" control={<Radio />} label="On" />
                    <FormControlLabel value="off" control={<Radio />} label="Off" />
                </RadioGroup>
            </FormControl>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button variant="contained" onClick={() => updateSensorQuery.mutate()}>
                    Modifier
                </Button>
            </DialogActions>
            <ErrorNotification open={openErrorNotification} message={errorMessage} />
        </Dialog>
    );
}
