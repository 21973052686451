import * as React from 'react';
import { Calendar, momentLocalizer, SlotInfo } from 'react-big-calendar';
import 'react-big-calendar/lib/sass/styles.scss';
import { useQuery } from 'react-query';

import { CircularProgress, Dialog } from '@mui/material';
import dayjs from 'dayjs';
import moment from 'moment/moment';

import PopupDataEventDisplay from './PopupDataEventDisplay';
import useEventService from '../../services/useEventService';

import styles from './EventTimetable.module.scss';
import './TimeIndicator.scss';

const localizer = momentLocalizer(moment);

interface CalendarEvent {
    title: string | undefined;
    start: Date;
    end: Date;
}

const undefinedString: string | undefined = 'undefined';
interface EventTimetableProps {
    roomUuid: string;
    setFormValue: React.Dispatch<
        React.SetStateAction<{
            uuid: string | undefined;
            title: string;
            startTime: string;
            endTime: string;
            date: string;
            attendees: string[];
        }>
    >;
    formValues: {
        uuid: string | undefined;
        title: string;
        startTime: string;
        endTime: string;
        date: string;
        attendees: string[];
    };
    setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const eventStyleGetter = () => {
    const style = {
        backgroundColor: '#bedaf0',
        borderRadius: '3px',
        opacity: 0.8,
        color: 'black',
        border: '1px solid black',
        marginLeft: '4px',
        paddingTop: '4px',
        alignItems: 'center',
        textAlign: 'center' as 'center',
    };
    return {
        style,
    };
};

const dayStyleGetter = () => {
    const style = {
        backgroundColor: '#fff',
    };
    return {
        style,
    };
};

function EventTimetable({ roomUuid, setFormValue, formValues, setDialogOpen }: EventTimetableProps) {
    const { getRoomEvents } = useEventService();
    const [open, setOpen] = React.useState(false);
    const [selectedEvent, setSelectedEvent] = React.useState({
        title: undefinedString,
        start: dayjs().toDate(),
        end: dayjs().toDate(),
    });
    const {
        data: events,
        isLoading,
        error,
    } = useQuery(['roomEvents', roomUuid], () => getRoomEvents(roomUuid), {
        enabled: !!roomUuid,
    });

    if (isLoading) {
        return <CircularProgress />;
    }

    if (error) {
        return <div>Error loading events</div>;
    }

    if (!events) {
        return <div>No events available</div>;
    }

    const convertToLocalTime = (datetime: string) => new Date(datetime);

    const eventsForCalendar = events.map((event) => ({
        title: event.name,
        start: convertToLocalTime(event.startDatetime ?? ''),
        end: convertToLocalTime(event.endDatetime ?? ''),
    }));

    const showSlotInfo = (slotInfo: SlotInfo) => {
        setFormValue({
            ...formValues,
            startTime: dayjs(slotInfo.start).format('HH:mm'),
            endTime: dayjs(slotInfo.end).format('HH:mm'),
        });
        setDialogOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const openDialog = (event: CalendarEvent) => {
        setSelectedEvent({ title: event.title, start: event.start, end: event.end });
        setOpen(true);
    };

    return (
        <div className={styles.container}>
            <Calendar
                localizer={localizer}
                events={eventsForCalendar}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 1000, color: 'black' }}
                defaultView="day"
                toolbar={false}
                onSelectSlot={showSlotInfo}
                onSelectEvent={openDialog}
                step={15}
                timeslots={4}
                selectable
                min={new Date(2023, 0, 0, 9, 0, 0, 0)}
                max={new Date(2023, 0, 0, 20, 0, 0, 0)}
                eventPropGetter={eventStyleGetter}
                dayPropGetter={dayStyleGetter}
            />
            <Dialog open={open} onClose={handleClose}>
                <PopupDataEventDisplay selectedEvent={selectedEvent} />
            </Dialog>
        </div>
    );
}

export default EventTimetable;
