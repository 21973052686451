import { createTheme } from '@mui/material';
import { green } from '@mui/material/colors';

const theme = createTheme({
    palette: {
        primary: {
            main: '#cb2b63',
        },
        secondary: {
            main: green[500],
        },
    },
});

export default theme;
