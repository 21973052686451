import React, { useEffect, useState } from 'react';

import MeetingRoom from '../../../assets/meetingRoom.svg';

export default function ErrorPage({ errorCode }: { errorCode: number }) {
    const [errorTitle, setErrorTitle] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');

    useEffect(() => {
        switch (errorCode) {
            case 404:
                setErrorMessage('Page introuvable');
                setErrorTitle('Erreur 404');
                break;
            case 500:
                setErrorMessage('Erreur serveur');
                setErrorTitle('Erreur 500');
                break;
            case 400:
                setErrorMessage('Accès interdit');
                setErrorTitle('Erreur 400');
                break;
            default:
                setErrorMessage('Erreur inconnue');
                setErrorTitle('Erreur');
                break;
        }
    }, [errorCode]);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '85vh',
                backgroundColor: '#f8f8f8',
                fontFamily: 'Arial, sans-serif',
                overflow: 'hidden',
            }}
        >
            <h1
                style={{
                    fontSize: '3rem',
                    fontWeight: 'bold',
                    marginBottom: '1rem',
                }}
            >
                {errorTitle}
            </h1>
            <h2
                style={{
                    fontSize: '1.5rem',
                    color: '#777',
                    marginBottom: '2rem',
                }}
            >
                {errorMessage}
            </h2>
            <img
                style={{
                    width: '300px',
                    marginTop: '2rem',
                }}
                src={MeetingRoom}
                alt="Meeting Room"
            />
        </div>
    );
}
