import * as React from 'react';
import { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { Gauge } from '@mui/x-charts/Gauge';

import useBulleStatService from '../../services/useBulleStatService';

export default function BulleOccupancyGauge({ bulleName, period }: Readonly<{ bulleName: string; period: string }>) {
    const { getBulleOccupancy } = useBulleStatService();
    const [bulleOccupancy, setBulleOccupancy] = useState<number>();

    useEffect(() => {
        getBulleOccupancy(bulleName, period, 20, 0).then((bulleStat) => {
            setBulleOccupancy(bulleStat.occupancyPercentage);
        });
    }, [bulleName, period]);

    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <Gauge width={100} height={100} value={bulleOccupancy ?? 0} text={({ value }) => `${value} %`} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            />
        </Box>
    );
}
