import * as React from 'react';
import { useQuery } from 'react-query';

import { CircularProgress, Grid } from '@mui/material';
import axios from 'axios';

import RoomCard from './RoomCard';
import Room from '../../../models/Room';
import useRoomService from '../../services/useRoomService';

import styles from './SallePage.module.scss';

interface RoomWithStatusProps {
    room: Room;
}

function RoomWithStatus({ room }: RoomWithStatusProps) {
    const fetchRoomCalendarStatus = async (roomName: string): Promise<string> => {
        const { data } = await axios.get<string>(`/stats/${roomName}/current-status`);
        return data;
    };

    const fetchRoomSensorStatus = async (roomId: string): Promise<string> => {
        const { data } = await axios.get<string>(`/rooms/${roomId}/sensor-status`);
        return data;
    };

    const {
        data: roomCalendarStatus,
        isLoading: calendarLoading,
        error: calendarError,
    } = useQuery(['roomCalendarStatus', room.name], () => fetchRoomCalendarStatus(room.name!), {
        enabled: !!room.name,
    });

    const {
        data: roomSensorStatus,
        isLoading: sensorLoading,
        error: sensorError,
    } = useQuery(['roomSensorStatus', room.uuid], () => fetchRoomSensorStatus(room.uuid!), { enabled: !!room.uuid });

    if (calendarLoading || sensorLoading) {
        return <CircularProgress />;
    }

    if (calendarError || sensorError) {
        return <div>Erreur lors du chargement du status de la salle</div>;
    }

    return (
        <Grid item>
            <RoomCard
                roomName={room.name!}
                roomCapacity={room.capacity!}
                roomStatus={roomCalendarStatus || 'unknown'}
                roomSensorStatus={roomSensorStatus || 'unknown'}
                roomPicture={room.imageName!}
                roomUuid={room.uuid!}
            />
        </Grid>
    );
}

export default function SallePage() {
    const { getRoomList } = useRoomService();

    const { data: rooms, isLoading, error } = useQuery('rooms', () => getRoomList(50, 0));

    if (isLoading) {
        return <CircularProgress />;
    }

    if (error) {
        return <div>Erreur lors du chargement des salles</div>;
    }

    return (
        <div className={styles.container}>
            <Grid container justifyContent="center" justifySelf="center" spacing={3}>
                {rooms?.map((room) => (
                    <RoomWithStatus key={room.uuid} room={room} />
                ))}
            </Grid>
        </div>
    );
}
