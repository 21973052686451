import * as React from 'react';
import { useMutation } from 'react-query';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { AxiosError } from 'axios';

import Bulle from '../../../models/Bulle';
import { ErrorData } from '../../common/types/types';
import useBullesService from '../../services/useBullesService';

export default function DeleteBullesDialog({
    open,
    onSuccess,
    onError,
    onClose,
    bulle,
    bulles,
    setBulles,
}: {
    open: boolean;
    onSuccess: () => void;
    onError: (message: string) => void;
    onClose: () => void;
    bulle: Bulle;
    bulles: Bulle[];
    setBulles: (value: ((prevState: Bulle[]) => Bulle[]) | Bulle[]) => void;
}) {
    const { deleteBulle } = useBullesService();

    const deleteUnsavedBulle = () => {
        bulles.splice(bulles.indexOf(bulle), 1);
        setBulles(bulles);
        onClose();
    };

    const deleteBulleQuery = useMutation(() => deleteBulle(bulle.uuid!), {
        onSuccess: () => {
            onSuccess();
            deleteUnsavedBulle();
        },
        onError: (error: AxiosError<ErrorData>) => {
            onError(error.response?.data.body.message!);
            onClose();
        },
    });

    return (
        <Dialog open={open}>
            <DialogTitle>Supprimer</DialogTitle>
            <DialogContent>Etes-vous sur de vouloir supprimer la bulle ?</DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    variant="contained"
                    onClick={
                        bulle.uuid
                            ? () => deleteBulleQuery.mutate()
                            : () => {
                                  deleteUnsavedBulle();
                                  onSuccess();
                              }
                    }
                >
                    Supprimer
                </Button>
            </DialogActions>
        </Dialog>
    );
}
