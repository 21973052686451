import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { Typography, Box, Table, TableBody, TableCell, TableHead, TableRow, Card } from '@mui/material';

import BulleOccupancy from '../../../models/BulleOccupancy';
import useBulleOccupancyService from '../../services/useBulleOccupancyService';
import useBullesService from '../../services/useBullesService';

import styles from './StatPage.module.scss';

type BulleStats = {
    name: string;
    duration: number;
};

function MostOccupiedBulle() {
    const { getBulleOccupancyList } = useBulleOccupancyService();
    const { getBulleList } = useBullesService();
    const { data: bulleOccupancies } = useQuery('bulleOccupancyList', () => getBulleOccupancyList(100, 0));
    const { data: bulles } = useQuery('bulleList', () => getBulleList(100, 0));
    const [bulleStats, setBulleStats] = useState<BulleStats[]>([]);

    useEffect(() => {
        if (bulleOccupancies && bulles) {
            const bulleStatsMap: { [key: string]: BulleStats } = {};

            bulleOccupancies
                .filter((occupancy: BulleOccupancy) => occupancy.status === 'done')
                .forEach((occupancy: BulleOccupancy) => {
                    if (occupancy.bulle?.uuid && occupancy.duration) {
                        const bulleName =
                            bulles.find((bulle) => bulle.uuid === occupancy.bulle?.uuid)?.name ?? occupancy.bulle?.uuid;

                        if (!bulleStatsMap[bulleName]) {
                            bulleStatsMap[bulleName] = { name: bulleName, duration: 0 };
                        }

                        bulleStatsMap[bulleName].duration += occupancy.duration;
                    }
                });

            const sortedBulleStats = Object.values(bulleStatsMap).sort((a, b) => b.duration - a.duration);
            setBulleStats(sortedBulleStats);
        }
    }, [bulleOccupancies, bulles]);

    const formatTime = (totalMinutes: number) => {
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        return `${hours}h ${minutes} min`;
    };

    return (
        <Card className={styles.bulle_stat}>
            <Typography variant="h5" component="div" sx={{ padding: '16px' }}>
                Les bulles les plus occupées
            </Typography>
            <Box sx={{ overflow: 'auto', width: { xs: '400px', sm: 'auto' } }}>
                <Table aria-label="bulle stats table" sx={{ whiteSpace: 'nowrap', mt: 2 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography variant="subtitle2" fontWeight={600}>
                                    Nom
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography variant="subtitle2" fontWeight={600}>
                                    Durée totale
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {bulleStats.map((bulle) => (
                            <TableRow key={bulle.name}>
                                <TableCell>
                                    <Typography sx={{ fontSize: '15px', fontWeight: '500' }}>{bulle.name}</Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography variant="h6">{formatTime(bulle.duration)}</Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        </Card>
    );
}

export default MostOccupiedBulle;
