import * as React from 'react';
import { ReactNode } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { Box, CssBaseline, Toolbar } from '@mui/material';

import SideBar from '../appBar/sideBar/SideBar';

import styles from './AppLayout.module.scss';

export default function AppLayout({ children }: { children: ReactNode }) {
    return (
        <Box>
            <CssBaseline />
            <Toolbar />
            <BrowserRouter>
                <div className={styles.sidebar}>
                    <SideBar />
                </div>
                <Box component="main">{children}</Box>
            </BrowserRouter>
        </Box>
    );
}
