import * as React from 'react';
import { useState } from 'react';
import { useMutation } from 'react-query';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import Event from '../../../models/Event';
import Room from '../../../models/Room';
import useEventService from '../../services/useEventService';

export default function UpdateEventDialog({
    open,
    onSuccess,
    onClose,
    event,
    roomList,
}: {
    open: boolean;
    onSuccess: () => void;
    onClose: () => void;
    event: Event;
    roomList: Room[];
}) {
    const [eventStartDateTime, setEventStartDateTime] = useState<Date>(new Date(event.startDatetime!));
    const [eventEndDateTime, setEventEndDateTime] = useState<Date>(new Date(event.endDatetime!));
    const [eventName, setEventName] = useState<string>(event.name!);
    const [eventRoom, setEventRoom] = useState<Room>(event.room!);

    const { updateEvent } = useEventService();

    const updateEventQuery = useMutation(
        () =>
            updateEvent({
                uuid: event.uuid,
                startDatetime: eventStartDateTime.toISOString(),
                endDatetime: eventEndDateTime.toISOString(),
                name: eventName,
                status: event.status,
                organizerEmail: event.organizerEmail,
                room: eventRoom,
                googleId: event.googleId,
                updated: event.updated,
            }),
        {
            onSuccess: () => onSuccess(),
        },
    );

    return (
        <Dialog open={open}>
            <DialogTitle>Modifier</DialogTitle>
            <DialogContent>
                <TextField
                    id="standard-basic"
                    label="Nom de l'évènement"
                    content={eventName}
                    variant="standard"
                    value={eventName}
                    onChange={(changeEvent) => setEventName(changeEvent.target.value)}
                />
            </DialogContent>
            <DialogContent>
                <Autocomplete
                    options={roomList}
                    getOptionLabel={(room: Room) => room.name!}
                    renderInput={(params) => <TextField {...params} label="Salle" />}
                    onChange={(_, value) => {
                        setEventRoom(value!);
                    }}
                    value={eventRoom}
                />
            </DialogContent>
            <DialogContent>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                        label="Début de la réunion"
                        value={eventStartDateTime}
                        onChange={(newValue) => {
                            if (newValue !== null) {
                                setEventStartDateTime(newValue);
                            }
                        }}
                        format="dd/MM/yyyy HH:mm"
                    />
                </LocalizationProvider>
            </DialogContent>
            <DialogContent>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                        label="Fin de la réunion"
                        value={eventEndDateTime}
                        onChange={(newValue) => {
                            if (newValue !== null) {
                                setEventEndDateTime(newValue);
                            }
                        }}
                        format="dd/MM/yyyy HH:mm"
                    />
                </LocalizationProvider>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        updateEventQuery.mutate();
                    }}
                >
                    Modifier
                </Button>
            </DialogActions>
        </Dialog>
    );
}
