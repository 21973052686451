import { useKeycloak } from '@react-keycloak/web';
import React from 'react';

import LoadingSpinner from '../common/LoadingSpinner';

interface KeycloakAuthenticatorProps {
    children: JSX.Element;
}

export default function KeycloakAuthenticator({ children }: KeycloakAuthenticatorProps) {
    const { keycloak, initialized } = useKeycloak();

    if (!initialized) {
        return <LoadingSpinner />;
    }

    const isLoggedIn = keycloak.authenticated;

    if (isLoggedIn) {
        return children;
    }

    if (isLoggedIn === false) {
        keycloak.login();
    }

    return <LoadingSpinner />;
}
