import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import MeetingRoom from '../../../assets/meetingRoom.svg';

import styles from './HomePage.module.scss';

export default function HomePage() {
    const navigate = useNavigate();

    const handleNavigate = (path: string) => {
        navigate(path);
    };

    return (
        <div className={styles.homeResponsive}>
            <h1> Bienvenue sur Flex Booking !</h1>
            <img className={styles.homeImg} src={MeetingRoom} alt="Meeting Room" />
            <div className={styles.buttonContainer}>
                <button type="button" onClick={() => handleNavigate('/map')} className={styles.mapHomeButton}>
                    Voir la carte
                </button>
                <button
                    type="button"
                    onClick={() => handleNavigate('/reservation')}
                    className={styles.reservationHomeButton}
                >
                    Réserver une salle
                </button>
            </div>
        </div>
    );
}
