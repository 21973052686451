import * as React from 'react';
import { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { Gauge } from '@mui/x-charts/Gauge';

import useStatService from '../../services/useStatService';

export default function RoomOccupancyGauge({ roomName, period }: Readonly<{ roomName: string; period: string }>) {
    const { getRoomOccupancy } = useStatService();
    const [roomOccupancy, setRoomOccupancy] = useState<number>();

    useEffect(() => {
        getRoomOccupancy(roomName, period, 20, 0).then((roomStat) => {
            setRoomOccupancy(roomStat.occupancy);
        });
    }, [roomName, period]);

    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <Gauge width={100} height={100} value={roomOccupancy ?? 0} text={({ value }) => `${value} %`} />
            <Box
                sx={{
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            />
        </Box>
    );
}
