import useFetchService from './useFetchService';
import Bulle from '../../models/Bulle';

export default function useBullesService() {
    const { getRequest, postRequest, putRequest, deleteRequest } = useFetchService();

    function getBulle(bulleUuid: string) {
        return getRequest<Bulle>(`bulles/${bulleUuid}`);
    }

    function getBulleList(limit: number, offset: number) {
        return getRequest<Bulle[], { limit: number; offset: number }>('bulles', { limit, offset });
    }

    function createBulle(bulle: Bulle) {
        return postRequest('bulles', bulle);
    }

    function updateBulle(bulle: Bulle) {
        return putRequest('bulles', bulle);
    }

    function deleteBulle(bulleUuid: string) {
        return deleteRequest(`bulles/${bulleUuid}`);
    }

    function fetchBulleStatus(bulleName: string) {
        return getRequest<String>(`/stats/${bulleName}/current-status`);
    }

    function fetchBulleSensorStatus(bulleId: string) {
        return getRequest<String>(`/bulles/${bulleId}/sensor-status`);
    }

    return { getBulle, getBulleList, createBulle, updateBulle, deleteBulle, fetchBulleStatus, fetchBulleSensorStatus };
}
