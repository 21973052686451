import useFetchService from './useFetchService';
import BulleOccupancy from '../../models/BulleOccupancy';

export default function useBulleOccupancyService() {
    const { getRequest } = useFetchService();

    function getBulleOccupancyList(limit: number, offset: number) {
        return getRequest<BulleOccupancy[], { limit: number; offset: number }>('bulleoccupancies', { limit, offset });
    }

    return { getBulleOccupancyList };
}
