import useFetchService from './useFetchService';
import BulleOccupancyStatsDto from '../../models/BulleOccupancyStatsDto';

export default function useBulleStatService() {
    const { getRequest } = useFetchService();

    function getBulleOccupancy(bulleName: string, period: string, limit: number, offset: number) {
        return getRequest<BulleOccupancyStatsDto, { bulleName: string; period: string; limit: number; offset: number }>(
            `bulleoccupancies/stats`,
            {
                bulleName,
                period,
                limit,
                offset,
            },
        );
    }

    function getBulleOccupancyCustom(
        bulleName: string,
        startDate: string,
        endDate: string | null,
        limit: number,
        offset: number,
    ) {
        return getRequest<
            BulleOccupancyStatsDto,
            { bulleName: string; startDate: string; endDate: string | null; limit: number; offset: number }
        >(`bulleoccupancies/stats/custom`, {
            bulleName,
            startDate,
            endDate,
            limit,
            offset,
        });
    }

    return { getBulleOccupancy, getBulleOccupancyCustom };
}
