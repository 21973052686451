import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams, Navigate } from 'react-router-dom';
import ReactSelect, { SingleValue } from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Fab,
    TextField,
    Chip,
    Box,
    Typography,
} from '@mui/material';
import dayjs from 'dayjs';

import EventTimetable from './EventTimeTable';
import Event from '../../../models/Event';
import UserDTO from '../../../models/UserDTO';
import useEventService from '../../services/useEventService';
import useUserService from '../../services/useUserService';

import styles from './RoomDetails.module.scss';

const MS_PER_MINUTES = 60000;
const STEP_SIZE = 15;

const DEFAULT_IMAGE_URL = 'https://via.placeholder.com/30';

function RoomDetails() {
    const { roomUuid, roomName } = useParams<{ roomUuid: string; roomName: string }>();
    const { getRoomEvents, createGoogleEvent } = useEventService();
    const { getUsers } = useUserService();

    const [dialogOpen, setDialogOpen] = useState(false);
    const [formValues, setFormValues] = useState({
        uuid: roomUuid,
        title: '',
        startTime: dayjs().format('HH:mm'),
        endTime: dayjs().add(1, 'hour').format('HH:mm'),
        date: dayjs().format('YYYY-MM-DD'),
        attendees: [] as string[],
    });
    const [selectedOption, setSelectedOption] = useState<SingleValue<{ value: string; label: JSX.Element }> | null>(
        null,
    );
    const [userOptions, setUserOptions] = useState<{ value: string; label: JSX.Element }[]>([]);

    const { data: events } = useQuery(['roomEvents', roomUuid], () => getRoomEvents(roomUuid!), {
        enabled: !!roomUuid,
    });

    const { data: users } = useQuery('accounts', () => getUsers());

    const fetchUsers = () => {
        try {
            const options = users?.map((user: UserDTO) => ({
                value: user.primaryEmail,
                label: (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                            src={user.photoUrl ?? DEFAULT_IMAGE_URL}
                            alt={user.fullName}
                            style={{ width: 30, height: 30, borderRadius: '50%', marginRight: 10 }}
                        />
                        <span>
                            {user.fullName} ({user.primaryEmail})
                        </span>
                    </div>
                ),
            }));
            setUserOptions(options as { value: string; label: JSX.Element }[]);
        } catch (error) {
            toast.error('Echec de récuperation d utilisateurs');
        }
    };

    useEffect(() => {
        if (dialogOpen) {
            fetchUsers();
        }
    }, [dialogOpen]);

    if (!roomUuid || !roomName) {
        return <Navigate to="/" replace />;
    }

    const handleClickOpen = () => {
        setDialogOpen(true);
    };

    const handleClose = () => {
        setDialogOpen(false);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const handleSelectChange = (selectedOption2: SingleValue<{ value: string; label: JSX.Element }>) => {
        const email = selectedOption2?.value ?? '';
        if (selectedOption2) {
            if (email.endsWith('@takima.fr') && !formValues.attendees.includes(email)) {
                setFormValues((prevState) => ({
                    ...prevState,
                    attendees: [...prevState.attendees, email],
                }));
                setSelectedOption(null);
            } else {
                toast.error('Invalid email or already added. Please enter a valid @takima.fr email.');
            }
        }
    };

    const handleDeleteEmail = (emailToDelete: string) => {
        setFormValues((prevState) => ({
            ...prevState,
            attendees: prevState.attendees.filter((email) => email !== emailToDelete),
        }));
    };

    const handleTimeButtonClick = (minutes: number) => {
        const startTime = dayjs(`${formValues.date}T${formValues.startTime}`);
        const endTime = startTime.add(minutes, 'minute').format('HH:mm');
        setFormValues((prevState) => ({
            ...prevState,
            endTime,
        }));
    };

    const toNearestLowerStep = (time: Date, step: number) => {
        let offset = 0;
        let rounded = Math.floor(time.getMinutes() / step) * step;
        if (rounded === 60) {
            offset = 1;
            rounded = 0;
        }
        return new Date(time.setHours(time.getHours() + offset, rounded));
    };

    const toNearestUpperStep = (time: Date, step: number) => {
        let offset = 0;
        let rounded = Math.ceil(time.getMinutes() / step) * step;
        if (rounded === 60) {
            offset = 1;
            rounded = 0;
        }
        return new Date(time.setHours(time.getHours() + offset, rounded));
    };

    const handleSubmit = async () => {
        const selectedStart = new Date(`${formValues.date ?? ''}T${formValues.startTime ?? ''}`);
        const selectedEnd = new Date(`${formValues.date ?? ''}T${formValues.endTime ?? ''}`);

        const now = new Date();

        if (selectedStart < new Date(now.valueOf() - 30 * MS_PER_MINUTES)) {
            toast.error('Impossible de faire une réservation aussi loin dans le passé');
            return;
        }

        const startHour = selectedStart.getHours();
        const endHour = selectedEnd.getHours();
        if (startHour < 9 || endHour >= 20) {
            toast.error('Heure de réservation doit être comprise entre 9h00 et 20h00.');
            return;
        }

        const isOverlapping = events?.some((event) => {
            const eventStart = new Date(event.startDatetime ?? '');
            const eventEnd = new Date(event.endDatetime ?? '');
            return (
                (selectedStart >= eventStart && selectedStart < eventEnd) ||
                (selectedEnd > eventStart && selectedEnd <= eventEnd) ||
                (selectedStart <= eventStart && selectedEnd >= eventEnd)
            );
        });

        if (isOverlapping) {
            toast.error('L’heure sélectionnée chevauche un événement existant.');
            return;
        }
        let startFormattedDate = toNearestLowerStep(selectedStart, STEP_SIZE);
        startFormattedDate = new Date(startFormattedDate.setHours(startFormattedDate.getHours() + 2));
        const startFormattedDateString = startFormattedDate.toISOString().replace('Z', '+02:00');
        let endFormattedDate = toNearestUpperStep(selectedEnd, STEP_SIZE);
        endFormattedDate = new Date(endFormattedDate.setHours(endFormattedDate.getHours() + 2));
        const endFormattedDateString = endFormattedDate.toISOString().replace('Z', '+02:00');
        try {
            await createGoogleEvent({
                uuid: formValues.uuid!,
                startDatetime: startFormattedDateString,
                endDatetime: endFormattedDateString,
                name: formValues.title,
                organizerEmail: '',
                status: 'confirmed',
                room: { uuid: roomUuid },
                attendees: formValues.attendees,
            }).then((res) => {
                events?.push(res as unknown as Event);
            });
            toast.success('Réservation soumise avec succès!');
            handleClose();
        } catch (error) {
            toast.error('Erreur lors de la soumission de la réservation.');
        }
    };

    return (
        <div className={styles.container}>
            <h2 className={styles.roomName}>{roomName}</h2>
            <EventTimetable
                roomUuid={roomUuid}
                setFormValue={setFormValues}
                setDialogOpen={setDialogOpen}
                formValues={formValues}
            />
            <Fab
                color="primary"
                aria-label="add"
                onClick={handleClickOpen}
                sx={{ position: 'fixed', bottom: 50, right: 30 }}
                className={styles.button}
            >
                <EditCalendarIcon />
            </Fab>
            <Dialog
                open={dialogOpen}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        height: '500px',
                    },
                }}
            >
                <DialogTitle>Réserver la salle</DialogTitle>
                <DialogContent>
                    <TextField
                        margin="dense"
                        name="title"
                        label="Titre"
                        type="text"
                        fullWidth
                        value={formValues.title}
                        onChange={handleChange}
                        className={styles.title}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' },
                            justifyContent: 'space-between',
                            gap: 0.5,
                        }}
                    >
                        <TextField
                            className={styles.dateField}
                            margin="dense"
                            name="date"
                            label="Date"
                            type="date"
                            disabled
                            fullWidth
                            value={formValues.date}
                            onChange={handleChange}
                        />
                        <TextField
                            margin="dense"
                            name="startTime"
                            label="Début"
                            type="time"
                            fullWidth
                            value={formValues.startTime}
                            onChange={handleChange}
                            className={styles.fieldMargin}
                        />
                        <TextField
                            margin="dense"
                            name="endTime"
                            label="Fin"
                            type="time"
                            fullWidth
                            value={formValues.endTime}
                            onChange={handleChange}
                            className={styles.fieldMargin}
                        />
                    </Box>
                    <Typography variant="body2" color="textSecondary" style={{ margin: '10px 0' }}>
                        Sélection rapide du temps de réservation :
                    </Typography>
                    <Button
                        className={styles.timeButton}
                        variant="contained"
                        startIcon={<HourglassTopIcon />}
                        color="primary"
                        onClick={() => handleTimeButtonClick(15)}
                    >
                        15m
                    </Button>
                    <Button
                        className={styles.timeButton}
                        variant="contained"
                        startIcon={<HourglassTopIcon />}
                        color="primary"
                        onClick={() => handleTimeButtonClick(30)}
                    >
                        30m
                    </Button>
                    <Button
                        className={styles.timeButton}
                        variant="contained"
                        startIcon={<HourglassTopIcon />}
                        color="primary"
                        onClick={() => handleTimeButtonClick(45)}
                    >
                        45m
                    </Button>
                    <Button
                        className={styles.timeButton}
                        variant="contained"
                        startIcon={<HourglassTopIcon />}
                        color="primary"
                        onClick={() => handleTimeButtonClick(60)}
                    >
                        1H
                    </Button>
                    <Box display="flex" alignItems="center" mb={2}>
                        <ReactSelect
                            key={`my_unique_select_key__${selectedOption?.value || ''}`}
                            value={selectedOption}
                            options={userOptions}
                            onChange={handleSelectChange}
                            placeholder="Rechercher un invité..."
                            isClearable
                            isSearchable
                            styles={{
                                container: (provided) => ({
                                    ...provided,
                                    flex: 1,
                                }),
                                menu: (provided) => ({
                                    ...provided,
                                    zIndex: 9999,
                                }),
                                menuList: (provided) => ({
                                    ...provided,
                                    maxHeight: '200px',
                                }),
                            }}
                        />
                    </Box>
                    <Box mt={2}>
                        {formValues.attendees.map((email) => (
                            <Chip
                                key={email}
                                label={email}
                                onDelete={() => handleDeleteEmail(email)}
                                style={{ margin: '2px' }}
                            />
                        ))}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Annuler
                    </Button>
                    <Button variant="contained" onClick={handleSubmit} color="primary">
                        Réserver
                    </Button>
                </DialogActions>
            </Dialog>
            <ToastContainer />
        </div>
    );
}

export default RoomDetails;
