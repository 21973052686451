import React from 'react';

import { Card, CardContent, Typography, Stack, Box, Avatar } from '@mui/material';
import { IconArrowUpLeft } from '@tabler/icons-react';

type Props = {
    title?: string;
    subtitle?: string;
    children?: JSX.Element;
    lastYearFigure?: string;
    percentageChange?: string;
};

function DashboardCard({ title, subtitle, children, lastYearFigure, percentageChange }: Readonly<Props>) {
    const successlight = '#e6fffa';
    return (
        <Card sx={{ padding: 0, width: '280px', marginTop: '18px', marginLeft: '122px' }} elevation={9}>
            <CardContent sx={{ p: '30px' }}>
                {title ? (
                    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" mb={3}>
                        <Box>
                            {title ? <Typography variant="h5">{title}</Typography> : ''}

                            {subtitle ? (
                                <Typography variant="subtitle2" color="textSecondary">
                                    {subtitle}
                                </Typography>
                            ) : (
                                ''
                            )}
                        </Box>
                    </Stack>
                ) : null}

                {children}

                {lastYearFigure && percentageChange ? (
                    <Stack direction="row" spacing={1} mt={1} alignItems="center">
                        <Avatar sx={{ bgcolor: successlight, width: 27, height: 27 }}>
                            <IconArrowUpLeft width={20} color="#39B69A" />
                        </Avatar>
                        <Typography variant="subtitle2" fontWeight="600">
                            {percentageChange}
                        </Typography>
                        <Typography variant="subtitle2" color="textSecondary">
                            {lastYearFigure}
                        </Typography>
                    </Stack>
                ) : null}
            </CardContent>
        </Card>
    );
}

DashboardCard.defaultProps = {
    title: '',
    subtitle: '',
    children: null,
    lastYearFigure: '',
    percentageChange: '',
};

export default DashboardCard;
