import * as React from 'react';
import { useQuery } from 'react-query';

import { BarChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Bar } from 'recharts';

import useEventService from '../../services/useEventService';

import styles from './StatPage.module.scss';

type Event = {
    status: string;
    room: { name: string } | null;
    startDatetime: string;
    endDatetime: string;
};

type RoomStats = {
    name: string;
    Occupation: number;
    formattedTimeSpent?: string;
};

const getXAxisData = (selectedPeriod: string): string[] => {
    switch (selectedPeriod) {
        case 'daily':
            return Array.from({ length: 10 }, (_, i) => `${9 + i}h`);
        case 'weekly':
            return ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi'];
        case 'monthly': {
            const daysInMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate();
            return Array.from({ length: daysInMonth }, (_, i) => `${i + 1}`);
        }
        case 'yearly':
            return ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'];
        default:
            return [];
    }
};

const formatTime = (Occupation: number): string => {
    const hours = Math.floor(Occupation);
    const minutes = Math.round((Occupation - hours) * 60);
    if (hours === 0) {
        return `${minutes}min`;
    }
    const minutesText = minutes > 0 ? `${minutes}min` : '';
    return `${hours}h ${minutesText}`.trim();
};

const processDailyData = (
    events: Event[],
    xAxisData: string[],
    roomStatsMap: { [key: string]: RoomStats },
    startOfDay: Date,
    endOfDay: Date,
    now: Date,
) => {
    events.forEach((event) => {
        if (event.room && event.room.name && event.startDatetime && event.endDatetime) {
            const startTime = new Date(event.startDatetime);
            const endTime = new Date(event.endDatetime);

            if (endTime >= startOfDay && endTime <= endOfDay) {
                const duration = (endTime.getTime() - startTime.getTime()) / (1000 * 60 * 60);

                xAxisData.forEach((hourLabel) => {
                    const hour = parseInt(hourLabel, 10);
                    const hourEnd = new Date(startOfDay);
                    hourEnd.setHours(hour, 0, 0, 0);

                    if (endTime <= hourEnd && hourEnd <= now) {
                        if (!roomStatsMap[hourLabel]) {
                            roomStatsMap[hourLabel] = { name: hourLabel, Occupation: 0 };
                        }
                        roomStatsMap[hourLabel].Occupation += duration;
                    }
                });
            }
        }
    });
};

const processWeeklyData = (
    events: Event[],
    xAxisData: string[],
    roomStatsMap: { [key: string]: RoomStats },
    currentWeekStart: Date,
    currentWeekEnd: Date,
) => {
    events.forEach((event) => {
        if (event.room && event.room.name && event.startDatetime && event.endDatetime) {
            const startTime = new Date(event.startDatetime);
            const endTime = new Date(event.endDatetime);

            if (startTime >= currentWeekStart && endTime <= currentWeekEnd) {
                const dayOfWeek = new Intl.DateTimeFormat('fr-FR', { weekday: 'long' }).format(startTime);
                const dayKey = xAxisData.find((day) => day.toLowerCase() === dayOfWeek.toLowerCase());

                if (dayKey) {
                    if (!roomStatsMap[dayKey]) {
                        roomStatsMap[dayKey] = { name: dayKey, Occupation: 0 };
                    }

                    const dayStart = new Date(startTime);
                    dayStart.setHours(9, 0, 0, 0);
                    const dayEnd = new Date(startTime);
                    dayEnd.setHours(18, 0, 0, 0);

                    const relevantStart = startTime > dayStart ? startTime : dayStart;
                    const relevantEnd = endTime < dayEnd ? endTime : dayEnd;

                    if (relevantStart <= relevantEnd) {
                        const durationForDay = (relevantEnd.getTime() - relevantStart.getTime()) / (1000 * 60 * 60);
                        roomStatsMap[dayKey].Occupation += durationForDay;
                    }
                }
            }
        }
    });
};

const processMonthlyData = (
    events: Event[],
    xAxisData: string[],
    roomStatsMap: { [key: string]: RoomStats },
    currentMonthStart: Date,
    currentMonthEnd: Date,
) => {
    events.forEach((event) => {
        if (event.room && event.room.name && event.startDatetime && event.endDatetime) {
            const startTime = new Date(event.startDatetime);
            const endTime = new Date(event.endDatetime);

            const startDate = new Date(startTime);
            const endDate = new Date(endTime);

            for (let date = new Date(currentMonthStart); date <= currentMonthEnd; date.setDate(date.getDate() + 1)) {
                const dayKey = `${date.getDate()}`;
                if (xAxisData.includes(dayKey)) {
                    if (!roomStatsMap[dayKey]) {
                        roomStatsMap[dayKey] = { name: dayKey, Occupation: 0 };
                    }

                    const dayStart = new Date(date);
                    dayStart.setHours(0, 0, 0, 0);
                    const dayEnd = new Date(date);
                    dayEnd.setHours(23, 59, 59, 999);

                    const relevantStart = startDate > dayStart ? startDate : dayStart;
                    const relevantEnd = endDate < dayEnd ? endDate : dayEnd;

                    if (relevantStart <= relevantEnd) {
                        const durationForDay = (relevantEnd.getTime() - relevantStart.getTime()) / (1000 * 60 * 60);
                        roomStatsMap[dayKey].Occupation += durationForDay;
                    }
                }
            }
        }
    });
};

const processYearlyData = (
    events: Event[],
    _xAxisData: string[],
    roomStatsMap: { [key: string]: RoomStats },
    currentYear: number,
) => {
    events.forEach((event) => {
        if (event.room && event.room.name && event.startDatetime && event.endDatetime) {
            const startTime = new Date(event.startDatetime);
            const endTime = new Date(event.endDatetime);

            const monthNames = ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'];

            monthNames.forEach((monthName, index) => {
                const monthStart = new Date(currentYear, index, 1);
                const monthEnd = new Date(currentYear, index + 1, 0, 23, 59, 59, 999);

                if (!roomStatsMap[monthName]) {
                    roomStatsMap[monthName] = { name: monthName, Occupation: 0 };
                }

                if (startTime >= monthStart && endTime <= monthEnd) {
                    const duration = (endTime.getTime() - startTime.getTime()) / (1000 * 60 * 60);
                    roomStatsMap[monthName].Occupation += duration;
                }
            });
        }
    });
};

const processData = (events: Event[], xAxisData: string[], period: string): RoomStats[] => {
    const roomStatsMap: { [key: string]: RoomStats } = {};
    const startOfDay = new Date();
    const now = new Date();
    startOfDay.setHours(9, 30, 0, 0);
    const endOfDay = new Date();
    endOfDay.setHours(18, 0, 0, 0);
    const currentMonthStart = new Date(now.getFullYear(), now.getMonth(), 1);
    const currentMonthEnd = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    const currentWeekStart = new Date(now);
    currentWeekStart.setDate(now.getDate() - now.getDay() + 1);
    currentWeekStart.setHours(9, 30, 0, 0);
    const currentWeekEnd = new Date(currentWeekStart);
    currentWeekEnd.setDate(currentWeekStart.getDate() + 6);
    currentWeekEnd.setHours(18, 0, 0, 0);

    switch (period) {
        case 'daily':
            processDailyData(events, xAxisData, roomStatsMap, startOfDay, endOfDay, now);
            break;
        case 'weekly':
            processWeeklyData(events, xAxisData, roomStatsMap, currentWeekStart, currentWeekEnd);
            break;
        case 'monthly':
            processMonthlyData(events, xAxisData, roomStatsMap, currentMonthStart, currentMonthEnd);
            break;
        case 'yearly':
            processYearlyData(events, xAxisData, roomStatsMap, now.getFullYear());
            break;
        default:
            break;
    }

    if (period === 'daily') {
        let previousTotal = 0;
        return xAxisData.map((label) => {
            const currentHourStats = roomStatsMap[label] || { name: label, Occupation: 0 };
            const currentTotal = currentHourStats.Occupation;

            const difference = Math.max(0, currentTotal - previousTotal);

            previousTotal = Math.max(previousTotal, currentTotal);
            return { ...currentHourStats, Occupation: difference };
        });
    }

    return xAxisData.map((label) => ({
        ...(roomStatsMap[label] || { name: label, Occupation: 0 }),
        formattedTimeSpent: formatTime(roomStatsMap[label]?.Occupation || 0),
    }));
};

const formatYAxisTick = (value: number): string => {
    if (value === 0) return '0h';
    const hours = Math.floor(value);
    const minutes = Math.round((value - hours) * 60);
    if (hours === 0) {
        return `${minutes}min`;
    }
    const minutesText = minutes > 0 ? `${minutes}min` : '';
    return `${hours}h ${minutesText}`.trim();
};

export default function RoomOverview({ period }: Readonly<{ period: string }>) {
    const { getEventList } = useEventService();
    const {
        data: eventList,
        isLoading: isEventListLoading,
        error,
    } = useQuery(['EventListPage', period], () => getEventList(1000, 0));

    if (isEventListLoading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error loading data</p>;
    }

    const xAxisData = getXAxisData(period);
    const roomOccupancyData = processData(eventList as Event[], xAxisData, period);

    return (
        <div className={styles.roomOverview}>
            <BarChart
                width={1000}
                height={550}
                data={roomOccupancyData}
                margin={{ top: 20, right: 30, bottom: 5, left: 5 }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis tickFormatter={formatYAxisTick} />
                <Tooltip formatter={formatTime} />
                <Legend />
                <Bar dataKey="Occupation" fill="#8884d8" name="Occupation globale" />
            </BarChart>
        </div>
    );
}
