import React from 'react';

import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

export default function ErrorNotification({ open, message }: { message: string | undefined; open: boolean }) {
    return (
        <Snackbar open={open} autoHideDuration={5000}>
            <MuiAlert severity="error" elevation={6} variant="filled">
                <strong>{message}</strong>
            </MuiAlert>
        </Snackbar>
    );
}
