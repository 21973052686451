import { useKeycloak } from '@react-keycloak/web';
import React from 'react';
import { Link } from 'react-router-dom';

import PeopleIcon from '@mui/icons-material/People';
import SensorsIcon from '@mui/icons-material/Sensors';
import { Card, CardContent, CardMedia, Typography, Box } from '@mui/material';

import styles from './MiniRoomCard.module.scss';

interface MiniRoomCardProps {
    roomName: string;
    roomUuid: string;
    roomCapacity: number;
    roomStatus: string;
    roomSensorStatus: string;
    roomPicture: string;
}

export default function MiniRoomCard({
    roomName,
    roomUuid,
    roomCapacity,
    roomStatus,
    roomSensorStatus,
    roomPicture,
}: MiniRoomCardProps) {
    const { keycloak } = useKeycloak();

    const getStatusColor = (status: string) => {
        switch (status.toLowerCase()) {
            case 'libre':
                return 'green';
            case 'occupée':
                return 'red';
            default:
                return 'orange';
        }
    };

    const getSensorStatusColor = (status: string) => {
        switch (status.toLowerCase()) {
            case 'on':
                return 'red';
            case 'off':
                return 'green';
            default:
                return 'gray';
        }
    };

    const linkUrl = keycloak?.authenticated
        ? `/roomdetails/${roomUuid}/${roomName}`
        : `/roomdetails-guest/${roomUuid}/${roomName}`;

    return (
        <Link to={linkUrl} className={styles.link}>
            <Card className={styles.card}>
                <Box className={styles.mediaContainer}>
                    <CardMedia
                        component="img"
                        className={styles.media}
                        image={roomPicture || 'conference room.jpg'}
                        alt={`${roomName} image`}
                    />
                </Box>
                <Box className={styles.contentBox}>
                    <CardContent className={styles.content}>
                        <Typography component="h5" variant="h5" className={styles.title}>
                            {roomName}
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary" className={styles.capacity}>
                            <PeopleIcon fontSize="small" className={styles.icon} /> {roomCapacity}
                        </Typography>
                        <div className={styles.statusContainer}>
                            <Typography
                                variant="subtitle1"
                                className={styles.status}
                                style={{ color: getStatusColor(roomStatus) }}
                            >
                                {roomStatus}
                            </Typography>
                            <SensorsIcon
                                fontSize="small"
                                className={styles.sensorIcon}
                                style={{ color: getSensorStatusColor(roomSensorStatus) }}
                            />
                        </div>
                    </CardContent>
                </Box>
            </Card>
        </Link>
    );
}
