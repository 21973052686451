import { useKeycloak } from '@react-keycloak/web';
import React from 'react';
import { Link } from 'react-router-dom';

import PeopleIcon from '@mui/icons-material/People';
import SensorsIcon from '@mui/icons-material/Sensors';
import { Card, CardContent, CardMedia, Typography, Box, Chip } from '@mui/material';

import styles from './RoomCard.module.scss';

interface RoomCardProps {
    roomName: string;
    roomUuid: string;
    roomCapacity: number;
    roomStatus: string;
    roomSensorStatus: string;
    roomPicture: string;
}

export default function RoomCard({
    roomName,
    roomUuid,
    roomCapacity,
    roomStatus,
    roomSensorStatus,
    roomPicture,
}: RoomCardProps) {
    const { keycloak } = useKeycloak();

    const getStatusColor = (status: string) => {
        switch (status.toLowerCase()) {
            case 'libre':
                return { text: 'green', back: '#e0f3e1' };
            case 'occupée':
                return { text: 'red', back: '#fde0e0' };
            default:
                return { text: 'orange', back: '#fbf8c5' };
        }
    };

    const getChipColor = (status: string) => {
        switch (status.toLowerCase()) {
            case 'libre':
                return 'success';
            case 'occupée':
                return 'error';
            default:
                return 'warning';
        }
    };

    const getSensorStatusColor = (status: string) => {
        switch (status.toLowerCase()) {
            case 'on':
                return { text: 'red', back: '#fde0e0' };
            case 'off':
                return { text: 'green', back: '#e0f3e1' };
            default:
                return { text: 'gray', back: '#e0e0e0' };
        }
    };

    const linkUrl = keycloak?.authenticated
        ? `/roomdetails/${roomUuid}/${roomName}`
        : `/roomdetails-guest/${roomUuid}/${roomName}`;

    return (
        <Link to={linkUrl} className={styles.link}>
            <div className={styles.cardContainer}>
                <Card className={styles.card}>
                    <Box className={styles.mediaContainer}>
                        <CardMedia
                            component="img"
                            className={styles.media}
                            image={roomPicture || '/conference_room.jpg'}
                            alt={`${roomName} image`}
                        />
                    </Box>
                    <Box className={styles.contentBox}>
                        <CardContent className={styles.content}>
                            <div className={styles.containerTitleCapacity}>
                                <Typography component="h5" variant="h5" className={styles.title}>
                                    {roomName}
                                </Typography>
                                <div className={styles.capacityContainer}>
                                    <PeopleIcon fontSize="small" className={styles.icon} />
                                    <Typography variant="subtitle1" color="textSecondary" className={styles.capacity}>
                                        {roomCapacity}
                                    </Typography>
                                </div>
                            </div>
                            <div className={styles.statusContainer}>
                                <Chip
                                    className={styles.status}
                                    style={{
                                        color: getStatusColor(roomStatus).text,
                                        backgroundColor: getStatusColor(roomStatus).back,
                                    }}
                                    label={roomStatus}
                                    variant="outlined"
                                    color={getChipColor(roomStatus)}
                                />
                                <SensorsIcon
                                    fontSize="small"
                                    className={styles.sensorIcon}
                                    style={{ color: getSensorStatusColor(roomSensorStatus).text }}
                                />
                            </div>
                        </CardContent>
                    </Box>
                </Card>
            </div>
        </Link>
    );
}
