import * as React from 'react';
import 'react-big-calendar/lib/sass/styles.scss';

import dayjs from 'dayjs';

import styles from './EventTimetable.module.scss';
import './TimeIndicator.scss';

interface CalendarEvent {
    title: string | undefined;
    start: Date;
    end: Date;
}

interface PopupDataEventProps {
    selectedEvent: CalendarEvent;
}

function PopupDataEventDisplay({ selectedEvent }: PopupDataEventProps) {
    const getTime = (date: Date) => {
        if (date.getMinutes() < 10) {
            return `${date.getHours()} h 0${date.getMinutes()}`;
        }
        return `${date.getHours()} h ${date.getMinutes()}`;
    };

    const getTimeStatus = (startDate: Date, endDate: Date) => {
        const now = dayjs().toDate();
        const minutesNow = now.getHours() * 60 + now.getMinutes();
        const minutesEnd = endDate.getHours() * 60 + endDate.getMinutes();
        const minutesStart = startDate.getHours() * 60 + startDate.getMinutes();
        const minutesdiffEnd = minutesEnd - minutesNow;
        const minutesdiffStart = minutesStart - minutesNow;
        if (now < startDate) {
            if (minutesdiffStart < 60) {
                return `Commence dans ${minutesdiffStart} minute(s)`;
            }
            return 'A venir';
        }
        if (now > endDate) {
            return 'Terminé';
        }
        if (minutesdiffEnd < 60) {
            return `Se termine dans ${minutesdiffEnd} minute(s)`;
        }
        return `Se termine dans ${Math.floor(minutesdiffEnd / 60)}h ${
            minutesdiffEnd % 60 < 10 ? `0${minutesdiffEnd % 60}` : minutesdiffEnd % 60
        }min`;
    };

    return (
        <div className={styles.popupContainer}>
            <h1 className={styles.popupTitle}>{selectedEvent.title}</h1>
            <p className={styles.status}>{getTimeStatus(selectedEvent.start, selectedEvent.end)}</p>
            <p className={styles.label}>Commence à</p>
            <h2 className={styles.popupTime}>{getTime(selectedEvent.start)}</h2>
            <p className={styles.label}>Termine à</p>
            <h2 className={styles.popupTime}>{getTime(selectedEvent.end)}</h2>
        </div>
    );
}

export default PopupDataEventDisplay;
